import { useHistory } from "react-router-dom";
import { Flex, Text, Button } from "@chakra-ui/react";

import React from "react";

export default function IrAVerificar(){
    const textColorSecondary = "gray.400";
    const history = useHistory();

    return(
        <Flex w='max-content' mx='auto' mt='15px' >
        <Flex align='center' direction='column'>
          <Text color={textColorSecondary}>
            Para poder invertir es necesario que verifiques tu identidad.
          </Text>
          <Text color={textColorSecondary}>
            Es muy sencillo, con un selfie tuyo y tu DNI o NIE.
          </Text>
          <Button
            variant="brand"
            size="md"
            mt='15px'
            onClick={(event) => {
              event.preventDefault();
              history.push("/auth/verify");
            }}
          >
            Verificar usuario
          </Button>
        </Flex>
      </Flex>   
    );
}