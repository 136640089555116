export function configHeader() {
  return {
    headers: {
      "Authorization": "Bearer " + getIdToken(),
      "Content-Type": "application/json",
    },
  }
};

function getIdToken() {
  return localStorage.getItem("token_cowallet");
}