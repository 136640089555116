import React, { useMemo, useState } from "react";
import Card from "components/card/Card.js";
import {
    Text, useColorModeValue, Button, Flex,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Slider,
    SliderTrack,
    SliderFilledTrack,
    SliderThumb,
    SliderMark,
    Box,
    SimpleGrid,
    Select, Icon, Editable,
    Tabs, TabList, Tab, Input, ModalFooter
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import MiniStatistics from "components/card/MiniStatistics";

import { MdSettings } from 'react-icons/md'

export default function Remesas(props) {
    const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
    const textColorSecondary = "gray.400";
    const history = useHistory();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [sliderValue, setSliderValue] = useState(50);
    const [sliderValueEnEuro, setSliderValueEnEuro] = useState("50 €");
    const [sliderValueUSDT, setSliderValueUSDT] = useState(0);
    const [paisDestino, setPaisDestino] = useState();
    const [nombrePaisDestino, setNombrePaisDestino] = useState();
    const [tasaCambioUsdt, setTasaCambioUsdt] = useState(1.07);
    const [tasaCambioMonedaDestino, setTasaCambioMonedaDestino] = useState(1.07);
    const [importeEnDestino, setImporteEnDestino] = useState();

    function actualizarValoresSlider(val) {
        setSliderValue(val);
        setSliderValueEnEuro(val + "€");
        let tasaCambio = tasaCambioUsdt;
        let valorEnUSDT = (tasaCambio * val).toFixed(2);
        setSliderValueUSDT(valorEnUSDT + " USDT");
        console.log(valorEnUSDT);
        console.log(tasaCambioMonedaDestino);

        let valorEnMonedaDestino = (valorEnUSDT / tasaCambioMonedaDestino)

        establecerValorConFormatoEnDestino(valorEnMonedaDestino);
    }


    function establecerValorConFormatoEnDestino(cantidad) {
        let culturaPais;
        let currency;
        switch (paisDestino) {
            case "co":
                culturaPais = 'es-CO';
                currency ='COP';
                break;
            case "pe":
                culturaPais = "es-PE";
                currency ='PEN';
                break;
            case "mx":
                culturaPais = "es-MX";
                currency = "MXN";
                break;
        }
        let valorConFormato = new Intl.NumberFormat(culturaPais, {
            style: 'currency',
            currency: currency,
            minimumFractionDigits: 0,
        }).format(cantidad);

        console.log("Valor Con formato :" + valorConFormato);
        setImporteEnDestino(valorConFormato);
    }

    function establecerPaisDestino(paisDestino) {
        setPaisDestino(paisDestino);
        let moneda;
        switch (paisDestino) {
            case "co":
                setNombrePaisDestino("Colombia");
                moneda = "COP";
                break;
            case "pe":
                setNombrePaisDestino("Perú");
                moneda = "PEN";
                break;
            case "mx":
                setNombrePaisDestino("México");
                moneda = "MXN";
                break;
        }

        const configSuper = {
            headers: {
                Authorization: "apiKey 1234",
            },
        };

        const urlTasaCamboi = process.env.REACT_APP_BASE_API_URL + "/TasaCambio";

        axios
            .get(urlTasaCamboi, configSuper)
            .then((response) => {
                setTasaCambioUsdt(response.data);
            })
            .catch((err) => {
                console.log(err);
            });


        const urlTasaCambioMoneda = process.env.REACT_APP_BASE_API_URL + "/TasaCambio?moneda=" + moneda;

        axios
            .get(urlTasaCambioMoneda, configSuper)
            .then((response) => {
                setTasaCambioMonedaDestino(response.data);
            })
            .catch((err) => {
                console.log(err);
            });

    }
    return (
        <Card mb={{ base: "0px", "2xl": "20px" }}>
            <Text
                color={textColorPrimary}
                fontWeight='bold'
                fontSize='2xl'
                mt='10px'
                mb='4px'>
                Tus Remesas
            </Text>
            <Text color={textColorSecondary} fontSize='md' me='26px' mb='40px'>
                Envía dinero a América Latina de forma segura, sin intermediarios y de forma económica con Cowallet
            </Text>

            <Flex mx='auto' mt='15px' >
                <Flex align='center' direction='column'>
                    <Text color={textColorSecondary} pl="15px">
                        Envía dinero ahora, simplemente debes seleccionar el importe, el país e introducir la dirección a la que deseas enviar el dinero.
                    </Text>
                    <Button
                        variant="brand"
                        size="md"
                        mt='15px'
                        onClick={(event) => {
                            event.preventDefault();
                            onOpen();
                        }}
                    >
                        Enviar dinero ahora!
                    </Button>
                </Flex>
            </Flex>
            <Modal isOpen={isOpen} onClose={onClose} size="4xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Enviar dinero ahora</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>

                        <Flex mb="1">
                            <Text>
                                ¿A donde vas a enviar el dinero?
                            </Text>
                        </Flex>

                        <Flex p="2">
                            <Select placeholder='A que pais quieres enviar dinero'
                                onChange={(selectedOption) => {
                                    selectedOption.preventDefault();
                                    establecerPaisDestino(selectedOption.target.value);

                                }}>

                                <option value='co'> Colombia</option>
                                <option value='pe'  >Perú</option>
                                <option value='mx'>Mexico</option>
                            </Select>
                        </Flex>
                        {
                            paisDestino === null || paisDestino === "" || paisDestino === undefined ?
                                <Text></Text> :
                                <Flex direction='column'>
                                    <Flex mb="5">
                                        <Text>
                                            ¿Cuanto dinero deseas enviar?
                                        </Text>
                                        <Editable defaultValue="Hola hola">
                                        </Editable>
                                    </Flex>
                                    <Flex py={{ base: "30px", md: "7px" }} p="5">

                                        <Slider
                                            aria-label="slider-ex-1"
                                            min={5}
                                            max={100}
                                            defaultValue={50}
                                            onChange={(val) => actualizarValoresSlider(val)}
                                        >
                                            <SliderMark
                                                value={sliderValue}
                                                textAlign="center"
                                                bg="blue.500"
                                                color="white"
                                                mt="-10"
                                                ml="-5"
                                                w="50"
                                            >
                                                {sliderValue}€
                                            </SliderMark>
                                            <SliderTrack>
                                                <SliderFilledTrack />
                                            </SliderTrack>
                                            <SliderThumb />
                                        </Slider>

                                    </Flex>
                                    <Flex justify="center">
                                        <Box pt={{ base: "0px", md: "0px", xl: "0px" }}>

                                            <SimpleGrid columns={{ base: 3, md: 3, xl: 3 }} >
                                                <MiniStatistics
                                                    name="Importe a Enviar"
                                                    value={sliderValueEnEuro}
                                                />
                                                <MiniStatistics

                                                    name="A"
                                                    value={nombrePaisDestino}
                                                />
                                                <MiniStatistics
                                                    name="Importe a recibir"
                                                    value={sliderValueUSDT}
                                                    growth={importeEnDestino}
                                                />
                                            </SimpleGrid>

                                        </Box>
                                    </Flex>
                                    <Flex mb="5">
                                        <Text>
                                            ¿Donde quieres recibir el dinero?
                                        </Text>
                                    </Flex>
                                    <Flex justify="center">

                                        <Tabs variant='unstyled'>
                                            <TabList>
                                                <Tab _selected={{ color: 'white', bg: 'blue.500' }}>Litio</Tab>
                                                <Tab _selected={{ color: 'white', bg: 'green.400' }}>Bit2Me </Tab>
                                                <Tab _selected={{ color: 'white', bg: 'green.400' }}>Binance </Tab>
                                                <Tab _selected={{ color: 'white', bg: 'green.400' }}>Coinbase </Tab>
                                            </TabList>
                                        </Tabs>
                                    </Flex>
                                    <Flex mb="5">
                                        <Text>
                                            y a que dirección lo vas a enviar
                                        </Text>
                                    </Flex>
                                    <Flex>
                                        <Input placeholder='Dirección de la wallet donde quieres recibir el dinero ' size='md' />
                                    </Flex>
                                </Flex>
                        }

                    </ModalBody>
                    <ModalFooter>

                        <Button colorScheme="brand" mr={3}
                            onClick={async (event) => {
                                event.preventDefault();
                                history.push("/auth/twoFAEnablement");
                            }}
                        >
                            Enviar
                        </Button>
                        <Button variant="ghost" onClick={onClose}>Cerrar</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Card>
    );
}