/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Box, Grid,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  ModalCloseButton,
  useDisclosure,
  Alert,
  AlertIcon,
  AlertDescription,
  Flex,
  Text,
  Spinner,
} from "@chakra-ui/react";

// Custom components
import Banner from "views/admin/profile/components/Banner";
import General from "views/admin/profile/components/General";
import Projects from "views/admin/profile/components/Projects";
import Remesas from "views/admin/profile/components/Remesas";
import Storage from "views/admin/profile/components/Storage";
import { columnasParticipaciones } from "views/admin/profile/variables/columnsData";
import { columnasTransacciones } from "views/admin/profile/variables/columnsData";

// Assets
import banner from "assets/img/auth/banner.png";
import avatar from "assets/img/avatars/avatarSimmmple.png";
import React, { useEffect, useState, useCallback } from "react";
import { configHeader } from "httpConfig";
import axios from "axios";
import { UserService } from "userService";
import toast from 'react-simple-toasts';
import { useHistory } from "react-router-dom";
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { Mixpanel } from 'mixpanel';

export default function Overview() {

  const [misParticipaciones, setMisParticipaciones] = useState([]);
  const [misTransacciones, setMisTransacciones] = useState([]);
  const [misTarjetas, setMisTarjetas] = useState([]);
  const [ordenesDepositoPendientes, setOrdenesDepositoPendientes] = useState([]);
  const [ordenDepositoEnConfirmacion, setOrdenDepositoEnConfirmacion] = useState();
  const [participacionesPendientesConfirmar, setParticipacionesPendientesConfirmar] = useState([]);
  const [tieneWallet, setTieneWallet] = useState(false);
  const [tieneTarjeta, setTieneTarjeta] = useState(false);
  const [saldo, setSaldo] = useState();
  const [importeInvertido, setImporteInvertido] = useState();
  const [importeRecuperado, setImporteRecuperado] = useState();
  const [interesesGanados, setInteresesGanados] = useState();
  const [saldoDisponible, setSaldoDisponible] = useState();
  const [nombreUsuario, setNombreUsuario] = useState("");
  const [usuarioVerificado, setUsuarioVerificado] = useState(false);
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpenConfirmarDeposito, onOpen: onOpenConfirmarDeposito, onClose: onCloseConfirmarDeposito } = useDisclosure()

  const [socketUrl, setSocketUrl] = useState('wss://ws.sandbox.bit2me.com/v1/trading');
  const [viewSpinner, setViewSpinner] = useState(true);

  const { sendMessage, lastMessage, readyState, sendJsonMessage } = useWebSocket(socketUrl, {
    onOpen: () => AutenticarWebsocket(),
    //Will attempt to reconnect on all close events, such as server shutting down
    onMessage: () => console.log("Llego un mensaje"),
    onError: (e) => console.log("Llego un error" + e),
    shouldReconnect: (closeEvent) => true,

  });

  function AutenticarWebsocket() {
    console.log('opened')
  }

  function EnviarMensajeAlWSBit2me() {
    console.log("Voy a enviar un mensaje");

    const header = configHeader();
    axios
      .get(process.env.REACT_APP_BASE_API_URL + "/Personas?conTokenB2M=true", header)
      .then((response) => {

        var otroJsonValue = JSON.stringify({ event: "authenticate", token: response.data.tokenBit2Me });
        var otroMensaje = JSON.stringify({ type: 'authenticate', payload: { token: response.data.tokenBit2Me } });
        console.log(otroJsonValue);

        sendMessage(otroJsonValue);

        var mensajePublico = JSON.stringify({
          event: "subscribe",
          symbol: "B2M/USDT",
          subscription: { name: "order-book" }
        });

        console.log(mensajePublico);
        sendMessage(mensajePublico);

      })
      .catch((err) => {
        console.log(err);
      });
  }
  useEffect(() => {
    console.log("Holaaa ultimo mensaje");
    if (lastMessage !== null) {
      console.log(lastMessage);
      // const msg = JSON.parse(lastMessage);
      // console.log(`websocket message: type: ${msg.type}, id: ${msg.payload.id}, time: ${msg.payload.time}`);
    }
  }, [lastMessage]);

  useEffect(() => {

    // Vamos a validar si el usuario está autenticado y en caso de que no, lo enviamos a la pantalla de login

    UserService.estaAutenticado().then((estaAutenticado) => {
      if (!estaAutenticado) {

        toast("Su sesión ha caducado, por favor vuelva a iniciar sesión", { position: 'top-center', duration: 5000 });

        history.push("/auth/sign-in");
      }

      setNombreUsuario(UserService.nombreUsuario());
      setUsuarioVerificado(UserService.estaVerificado());
    });

    CargarMisParticipaciones();

    // Vamos a cargar el estado de la wallet y en caso de que no exista la creamos
    CargarEstadoWallet();

    // Vamos a mostrar las ordenes de deposito pendientes para que el usuario las actualice
    CargarOrdenesDepositoPendientes();

    CargarTransacciones();

    CargarParticipacionesPendientesConfirmar();

    Mixpanel.track('Acceso_Perfil');

  }, []);


  function CargarOrdenesDepositoPendientes() {
    const header = configHeader();
    axios
      .get(process.env.REACT_APP_BASE_API_URL + "/Wallet/OrdenesDeposito?SoloPendientes=true", header)
      .then((response) => {
        setOrdenesDepositoPendientes(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function CargarEstadoWallet() {
    const header = configHeader();
    axios
      .get(process.env.REACT_APP_BASE_API_URL + "/Wallet", header)
      .then((response) => {

        EstablecerValoresWallet(response);
        console.log("Cargados los valores de la wallet");
        if (response.data.noExiste) {

          axios
            .post(
              process.env.REACT_APP_BASE_API_URL + "/Wallet", null,
              header
            )
            .then((response) => {
              EstablecerValoresWallet(response);
            })
            .catch((err) => {

              MostrarToast("Ha ocurrido un error al crear la wallet, por favor, ponte en contacto con nosotros por el chat.");
              console.log(err);

            });
        }
      })
      .catch((err) => {

        MostrarToast("Ha ocurrido un error al obtener información de tu wallet, por favor, ponte en contacto con nosotros por el chat.");
        console.log(err);
      });
  }


  function MostrarToast(mensaje) {

    toast(mensaje, { position: 'top-center', duration: 5000 });

  }

  function EstablecerValoresWallet(response) {
    setTieneWallet(!response.data.noExiste);
    setTieneTarjeta(response.data.tieneTarjeta);
    setSaldo(response.data.importeDisponibleInvertirString);
    setImporteInvertido(response.data.saldoPendienteEnviarString);
    setImporteRecuperado(response.data.importeRecuperadoString);
    setInteresesGanados(response.data.interesesGanadosString);
    setSaldoDisponible(response.data.saldoString);
    setMisTarjetas(response.data.tarjetasCredito);
  }

  async function CargarTransacciones() {
    const header = configHeader();
    axios
      .get(process.env.REACT_APP_BASE_API_URL + "/Wallet/Transacciones", header)
      .then((response) => {
        setMisTransacciones(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  async function CargarParticipacionesPendientesConfirmar() {
    const header = configHeader();
    axios
      .get(process.env.REACT_APP_BASE_API_URL + "/MisParticipaciones?pendientesConfirmar=true", header)
      .then((response) => {
        setParticipacionesPendientesConfirmar(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function CargarMisParticipaciones() {
    const header = configHeader();
    axios
      .get(process.env.REACT_APP_BASE_API_URL + "/MisParticipaciones", header)
      .then((response) => {
        setMisParticipaciones(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function cerrarAnyadirTarjeta() {
    onClose();

    const header = configHeader();

    axios
      .put(
        process.env.REACT_APP_BASE_API_URL + "/ActualizarTarjetas", null,
        header
      )
      .then((response) => {
        EstablecerValoresWallet(response);
        MostrarToast("¡Actualizado correctamente el estado de las tarjetas, gracias!");
      })
      .catch((err) => {

        MostrarToast("Ha ocurrido un error al actualizar las tarjetas de la wallet, por favor, ponte en contacto con nosotros por el chat.");
        console.log(err);

      });

  }

  function abrirAnyadirTarjeta() {

    onOpen();
    setViewSpinner(true);
    setTimeout(() => cambiarUrlFrame(), 1000);

  }


  function cambiarUrlFrame() {
    const header = configHeader();
    axios
      .get(process.env.REACT_APP_BASE_API_URL + "/Personas?conTokenB2M=true", header)
      .then((response) => {

        let urlAnyadirTarjeta = `${process.env.REACT_APP_BASE_BIT2ME_URL}/add-card?t=${response.data.tokenBit2Me}&lang=es`;

        document.getElementById('anyadirTarjetaFrame').src = urlAnyadirTarjeta;

        let srciFrame = document.getElementById('anyadirTarjetaFrame').src;

        setViewSpinner(false);
        console.log("Este es el src del frame = " + srciFrame);
      })
      .catch((err) => {
        console.log(err);
      });

  }
  function ConfirmarParticipacion(idParticipacion) {
    console.log(idParticipacion);
    const header = configHeader();

    axios
      .put(
        process.env.REACT_APP_BASE_API_URL + "/MisParticipaciones/" + idParticipacion + "/Confirmar", null,
        header
      )
      .then((response) => {

        MostrarToast("¡Participación condfirmada correctamente, gracias!");
        window.location.reload(false);
      })
      .catch((err) => {

        MostrarToast("Ha ocurrido un error al confirmar la participación, por favor, ponte en contacto con nosotros por el chat.");
        console.log(err);

      });
  }
  function CerrarPopupConfirmarDeposito()
  {
    console.log("Vamos a aprobar el deposito");
    const header = configHeader();
    
    axios
      .put(
        process.env.REACT_APP_BASE_API_URL + "/Wallet/OrdenesDeposito/" + ordenDepositoEnConfirmacion + "/Aprobar", null,
        header
      )
      .then((response) => {

        MostrarToast("¡Añadido correctamente el saldo, gracias!");

        window.location.reload(false);
      })
      .catch((err) => {

        MostrarToast("Ha ocurrido un error al confirmar el deposito, por favor, ponte en contacto con nosotros por el chat.");
        console.log(err);

      });

      onCloseConfirmarDeposito();
  }
  function ConfirmarOrdenDeposito(idOrden) {
    console.log(idOrden);
    const header = configHeader();

    setOrdenDepositoEnConfirmacion(idOrden);
    
    axios
      .put(
        process.env.REACT_APP_BASE_API_URL + "/Wallet/OrdenesDeposito/" + idOrden + "/Confirmar", null,
        header
      )
      .then((response) => {

        MostrarToast("¡Añadido correctamente el saldo, gracias!");

        if (response.data.estado === 1) {

          onOpenConfirmarDeposito();

          console.log(response.data.uriConfirmacion);
          document.getElementById('confirmarDepositoFrame').src = response.data.uriConfirmacion;

          let srciFrameConfirmarDep = document.getElementById('anyadirTarjetaFrame').src;

        }

      })
      .catch((err) => {

        MostrarToast("Ha ocurrido un error al confirmar el deposito, por favor, ponte en contacto con nosotros por el chat.");
        console.log(err);

      });
    Mixpanel.track('Confirmar_Deposito');
  }

  return (

    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {/* Main Fields */}
      <Flex
        display={
          participacionesPendientesConfirmar.length > 0 ? "flex" : "none"
        }
        direction="column"

        mb='10px'
      >
        {
          participacionesPendientesConfirmar.map((participacion, index) => (

            <Alert
              key={"_alert_" + participacion.id}
              status="warning"
              variant="left-accent"
            >
              <AlertIcon></AlertIcon>
              <AlertDescription>
                <Flex direction="inherit">
                  La participación del {participacion.porcentajeString} con importe {participacion.importeInvertidoString} está pendiente de confirmar
                  <Button size='xs'
                    onClick={(event) => {
                      event.preventDefault();
                      ConfirmarParticipacion(participacion.id);
                    }}
                  >
                    Confirmar
                  </Button>

                </Flex>
              </AlertDescription>
            </Alert>
          ))
        }

      </Flex>
      <Flex
        display={
          ordenesDepositoPendientes.length > 0 ? "flex" : "none"
        }
        direction="column"

        mb='10px'
      >
        {
          ordenesDepositoPendientes.map((ordenDeposito, index) => (

            <Alert
              key={"_alert_" + ordenDeposito.id}
              status="info"
              variant="left-accent"
            >
              <AlertIcon></AlertIcon>
              <AlertDescription>
                <Flex direction="inherit">
                  La orden de deposito {ordenDeposito.descripcion} está pendiente de confirmar
                  <Button size='xs'
                    onClick={(event) => {
                      event.preventDefault();
                      ConfirmarOrdenDeposito(ordenDeposito.bit2MeOrderId);
                    }}
                  >
                    Confirmar
                  </Button>

                </Flex>
              </AlertDescription>
            </Alert>
          ))
        }

      </Flex>
      <Grid
        templateColumns={{
          base: "1fr",
          lg: "1fr 1.62fr",
        }}
        templateRows={{
          base: "repeat(2, 1fr)",
          lg: "1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}>
        <Banner
          gridArea='1 / 1 / 2 / 2'
          banner={banner}
          avatar={avatar}
          name={nombreUsuario}
          job='Inversor particular'
          saldo={saldo}
          followers='9.7k'
          following='274'
          estaVerificado={usuarioVerificado}
          abrirAnyadirTarjeta={abrirAnyadirTarjeta}
          tieneTarjeta={tieneTarjeta}
        />
        <Storage
          gridArea={{ base: "2 / 1 / 3 / 2", lg: "1 / 2 / 2 / 3" }}
          used={saldo}
          total={importeInvertido}
          tieneWallet={tieneWallet}
          importeRecuperado={importeRecuperado}
          interesesGanados={interesesGanados}
          saldoDisponible={saldoDisponible}
          abrirAnyadirTarjeta={abrirAnyadirTarjeta}
          misTarjetas={misTarjetas}
        />

      </Grid>
      <Grid
        mb='20px'
        templateColumns={{
          base: "1fr",
          lg: "repeat(2, 1fr)",
          "2xl": "1.34fr 1.62fr",
        }}
        templateRows={{
          base: "1fr",
          lg: "repeat(2, 1fr)",
          "2xl": "1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}>
          <Remesas></Remesas>
        {/* <Projects
          gridArea='1 / 2 / 2 / 2'
          banner={banner}
          avatar={avatar}
          tableData={misParticipaciones}
          columnsData={columnasParticipaciones}
          name='Adela Parkson'
          job='Product Designer'
          posts='17'
          followers='9.7k'
          following='274'
        /> */}
        <General
          gridArea={{ base: "2 / 1 / 3 / 2", lg: "1 / 2 / 2 / 3" }}
          minH='365px'
          pe='20px'
          tableData={misTransacciones}
          columnsData={columnasTransacciones}
        />
      </Grid>

      <Modal isOpen={isOpenConfirmarDeposito} onOpen={onOpenConfirmarDeposito} onClose={CerrarPopupConfirmarDeposito} size="4xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirmar el deposito</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex >
              <iframe
                title="Grid Generator"
                id="confirmarDepositoFrame"
                src=""
                width="100%"
                height="500px"
              />
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal isOpen={isOpen} onClose={cerrarAnyadirTarjeta} size="4xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Añadir tarjeta de crédito</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Ingresa datos de la tarjeta y una vez añadida por favor cierra la ventana.

            <Flex display={!viewSpinner ? "flex" : "none"}>
              <iframe
                title="Grid Generator"
                id="anyadirTarjetaFrame"
                src=""
                width="100%"
                height="450px"
              />
            </Flex>
            <Flex
              display={viewSpinner ? "flex" : "none"}
              direction="column"
              align="center"
              justifyContent="center"
              w={{ base: "100%", md: "420px" }}
              maxW="100%"
              background="transparent"
              borderRadius="15px"
              mx={{ base: "auto", lg: "unset" }}
              me="auto"
              mb={{ base: "20px", md: "auto" }}
              mt="20px"
            >
              <Text>Espera un momento ...</Text>
              <Spinner
                mt="20px"
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
              />
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="brand" mr={3} onClick={cerrarAnyadirTarjeta}>
              Cerrar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

    </Box>

  );
}
