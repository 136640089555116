import axios from "axios";
import { configHeader } from "httpConfig";

let usuario = [];

async function ObtenerInfomacionUsuario() {

    const header = configHeader();

    const result = await axios
        .get(process.env.REACT_APP_BASE_API_URL + "/Personas", header)
        .then((response) => {
            console.log("Venga que pasa con esto?");
            usuario = response.data;
            return response;
        })
        .catch((err) => {
            if (err.code === "ERR_BAD_REQUEST") {

                return null;
            }
            else {
                throw (err);
            }
        });

    return result;

}
let actions = {

    estaAutenticado: async () => {

        let token = localStorage.getItem("token_cowallet");

        if (token === undefined || token === null) {
            return false;
        }

        var result = await ObtenerInfomacionUsuario();

        if (result === null) {
            return false;
        }

        return true;
    },
    estaVerificado: () => {
        if (usuario === null) {
            return false;
        }

        return (usuario.noExiste === false && usuario.tieneKYC === true);

    },
    tokenBit2Me: () => {
        return usuario.tokenBit2Me;
    },

    nombreUsuario: () => {

        if (usuario === null) {
            return "No existe usuario";
        }

        if (usuario.noExiste === true) {
            return usuario.email;
        }

        return usuario.nombre + " " + usuario.apellidos;
    },
};

export let UserService = actions;