/* eslint-disable */
import {
  Flex,
  Progress,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  SimpleGrid,
  Box,
  Icon,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  AlertTitle,
  Heading,
  Alert,
  AlertIcon,
  AlertDescription,
  CloseButton,
  Show, Stack, StackDivider, StatGroup, Stat, StatHelpText, StatNumber,
  List, ListItem, ListIcon, Link

} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import ColumnsTable from "views/admin/dataTables/components/ColumnsTable";
import { columnsDataColumns } from "views/admin/dataTables/variables/columnsData";
import PieCard from "views/admin/default/components/PieCard";

import Menu from "components/menu/MainMenu";
import React, { useMemo, useState, useEffect } from "react";
import Banner from "views/admin/marketplace/components/Banner";
import axios from "axios";
import { CheckCircleIcon } from '@chakra-ui/icons'
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

import { NavLink } from "react-router-dom";
import { MdAutoGraph, MdOutlineEuro } from "react-icons/md";
import { Mixpanel } from 'mixpanel';
import { UserService } from "userService";
import { configHeader } from "httpConfig";
import { useHistory } from "react-router-dom";
import toast from 'react-simple-toasts';

export default function DevelopmentTable(props) {

  const [usuarioEstaVerificado, setusuarioEstaVerificado] = useState(false);
  const [tokenBit2me, setTokenBit2me] = useState([]);
  const { columnsData, tableData } = props;
  const [campanyaSeleccionada, setCampanyaSeleccionada] = useState([]);
  const [pagosCampanyaSeleccionada, setPagosCampanyaSeleccionada] = useState(
    []
  );
  const [porcentajeFinanciado, setPorcentajeFinanciado] = useState(50);
  const [porcentajeEnEspera, setPorcentajeEnEspera] = useState(15);
  const [mostrarUsarBonoBienvenida, setMostrarUsarBonoBienvenida] = useState("none");
  const [mostrarBotonCrearPersona, setMostrarBotonCrearPersona] = useState("none");
  
  const [tasaCambioUsdt, setTasaCambioUsdt] = useState(1.07);
  const [mostrarIrARegistro, setMostrarIrARegistro] = useState("none");
  const [mostrarVerificarUsuario, setMostrarVerificarUsuario] =
    useState("none");

  useEffect(() => {
    obtenerInformacionPersona();

    setTimeout(() => {
      obtenerInformacionBonoBienvenida();
    }, 350);

    obtenerTasaCambio();

  }, []);

  useEffect(() => {
    console.log("hola este es el id campaña" + campanyaSeleccionada.id);

    if (
      campanyaSeleccionada.porcentajeEnEspera !== undefined &&
      campanyaSeleccionada.porcentajeEnEspera !== null
    ) {
      console.log(
        "hola este es P espera: " + campanyaSeleccionada.porcentajeEnEspera
      );
      setPorcentajeEnEspera(campanyaSeleccionada.porcentajeEnEspera);
    }

    if (
      campanyaSeleccionada.porcentajeFinanciado !== undefined &&
      campanyaSeleccionada.porcentajeFinanciado !== null
    ) {
      console.log(
        "hola este es P espera: " + campanyaSeleccionada.porcentajeFinanciado
      );
      setPorcentajeFinanciado(campanyaSeleccionada.porcentajeFinanciado);
    }
  }, [campanyaSeleccionada]);

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 11;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const iconColor = useColorModeValue("secondaryGray.500", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isVisible, onOpen: onOpenAlert, onClose: onCloseAlert } = useDisclosure({ defaultIsOpen: true })
  const { isOpen: isVisiblePopupBono, onOpen: onOpenPopupBono, onClose: onClosePopupBono } = useDisclosure()

  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  async function obtenerTasaCambio() {
    const configSuper = {
      headers: {
        Authorization: "apiKey 1234",
      },
    };

    const urlTasaCamboi = process.env.REACT_APP_BASE_API_URL + "/TasaCambio";

    axios
      .get(urlTasaCamboi, configSuper)
      .then((response) => {
        setTasaCambioUsdt(response.data);
      })
      .catch((err) => {
        console.log(err);
      });

  }
  async function obtenerInformacionBonoBienvenida() {
    console.log("Iniciamos con el bono");
    // Vamos a mostar el boton de usar el bono de bienvenida
    let estaAutenticado = await UserService.estaAutenticado();
    if (estaAutenticado === true) {
      const header = configHeader();
      axios
        .get(process.env.REACT_APP_BASE_API_URL + "/Wallet/BonoBienvenida", header)
        .then((response) => {
          console.log(response)
          if (response.data.noExistePersona) {
            setMostrarBotonCrearPersona("flex")
          }
          else {
            if (!response.data.estaUsado) {
              setMostrarBotonCrearPersona("none")
              setMostrarUsarBonoBienvenida("flex")
            }
          }

        })
        .catch((err) => {
          MostrarToast("Error al obtener la información del bono de bienvenida");
          console.log(err);
        });
    }
  }
  async function obtenerInformacionPersona() {
    let estaAutenticado = await UserService.estaAutenticado();

    if (estaAutenticado) {
      setMostrarIrARegistro("none");
      setMostrarVerificarUsuario("none");

      let estaVerificado = UserService.estaVerificado();
      setusuarioEstaVerificado(estaVerificado);

      if (!estaVerificado) {
        setTokenBit2me(UserService.tokenBit2Me());
        setMostrarVerificarUsuario("flex");
      }

    } else {
      setMostrarIrARegistro("flex");
      setMostrarVerificarUsuario("none");
    }
  }

  function seleccionarYCargarCampanya(idCampanya) {
    console.log(idCampanya);

    setCampanyaSeleccionada([]);

    const configSuper = {
      headers: {
        Authorization: "apiKey 1234",
      },
    };

    const urlCampanya = process.env.REACT_APP_BASE_API_URL + "/Campanyas/" + idCampanya;

    axios
      .get(urlCampanya, configSuper)
      .then((response) => {
        setCampanyaSeleccionada(response.data);
        setPagosCampanyaSeleccionada(response.data.pagos);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const OverlayOne = () => (
    <ModalOverlay
      bg='blackAlpha.300'
      backdropFilter='blur(10px) hue-rotate(90deg)'
    />
  )

  const [overlay, setOverlay] = React.useState(<OverlayOne />)
  const history = useHistory();

  function irAVerificar() {
    history.push("/auth/verify");
  }

  async function aplicarBono() {
    const header = configHeader();

    await axios
      .post(
        process.env.REACT_APP_BASE_API_URL + "/Wallet/BonoBienvenida",
        {},
        header
      )
      .then((response) => {
        history.push("/admin/profile");
      })
      .catch((err) => {
        MostrarToast("Error al aplicar el bono de bienvenida. Por favor ponte en contacto con nosotros para solucionarlo")
      });

  }
  function MostrarToast(mensaje) {

    toast(mensaje, { position: 'top-center', duration: 5000 });

  }
  return (
    <Box>
      {/* {
        isVisible ? (
          <Box>
            <Show below='md'>
              <Alert
                status='info'
                variant='subtle'
                flexDirection='column'
                alignItems='center'
                justifyContent='center'
                textAlign='center'
                key="_alert_BonoBienvenida"
                isOpen={isVisible} onClose={onCloseAlert}
                mb="8px"
              >
                <CloseButton
                  alignSelf='flex-end'
                  position='absolute'
                  right={-1}
                  top={-1}
                  onClick={onCloseAlert}
                />
                <AlertTitle mt={4} mb={1} fontSize='lg'>
                  Haz tu primera inversión sin compromiso
                </AlertTitle>
                <AlertDescription maxWidth='sm'>
                  <Flex direction="column">
                    Usa el bono virtual de 100€ de bienvenida.
                    &nbsp; &nbsp;
                  </Flex>
                </AlertDescription>
                <Button size='xs'
                  alignSelf='center'
                  position='relative'
                  right={-1}
                  top={2}
                  onClick={(event) => {
                    setOverlay(<OverlayOne />)
                    onOpenPopupBono()
                  }}
                >
                  Más información
                </Button>
                <Button display={mostrarBotonCrearPersona} mt="15px"
                  onClick={(event) => {
                    irAVerificar()
                  }}>
                  Verificar usuario
                </Button>
                <Button display={mostrarUsarBonoBienvenida} mt="15px"
                  onClick={(event) => {
                    aplicarBono()
                  }}>
                  Usar el bono ahora
                </Button>
              </Alert>
            </Show>
            <Show above='md'>
              <Alert mb="5px"
                key="_alert_BonoBienvenida"
                status="info"
                variant="left-accent"
              >
                <AlertIcon></AlertIcon>
                <AlertDescription>
                  <Flex direction="inherit">
                    Haz tu primera inversión y prueba el servicio sin compromiso con el bono virtual de 100€ que te damos de bienvenida.
                    &nbsp; &nbsp; <Button size='xs'
                      onClick={(event) => {
                        setOverlay(<OverlayOne />)
                        onOpenPopupBono()
                      }}
                    >
                      Más información
                    </Button>
                    &nbsp; &nbsp;
                    <Button display={mostrarBotonCrearPersona}
                      onClick={(event) => {
                        irAVerificar()
                      }}>
                      Verificar usuario
                    </Button>
                    &nbsp; &nbsp;
                    <Button display={mostrarUsarBonoBienvenida}
                      onClick={(event) => {
                        aplicarBono()
                      }}>
                      Usar el bono ahora
                    </Button>
                  </Flex>
                </AlertDescription>
              </Alert>
            </Show>

          </Box>
        ) : (
          <Flex></Flex>
        )
      } */}

      <Card
        direction="column"
        w="100%"
        px="0px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Flex px="25px" justify="space-between" mb="20px" align="center">
          <Text
            color={textColor}
            fontSize="22px"
            fontWeight="700"
            lineHeight="100%"
          >
            Préstamos disponibles
          </Text>
        </Flex>
        <Show above='md'>
          <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
            <Thead>
              {headerGroups.map((headerGroup, index) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <Th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      pe="15px"
                      key={index}
                      borderColor={borderColor}
                    >
                      <Flex
                        justify="space-between"
                        align="center"
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color="gray.400"
                      >
                        {column.render("Header")}
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {page.map((row, index) => {
                prepareRow(row);
                return (
                  <Tr {...row.getRowProps()} key={index}>
                    {row.cells.map((cell, index) => {
                      let data = "";
                      if (cell.column.Header !== "Progreso" && cell.column.Header !== "INVERTIR") {
                        data = (
                          <Text color={textColor} fontSize="sm" fontWeight="700">
                            {cell.value}
                          </Text>
                        );
                      } else if (cell.column.Header === "Progreso") {
                        data = (
                          <Flex align="center" direction="column">
                            <Text
                              me="10px"
                              color={textColor}
                              fontSize="sm"
                              fontWeight="700"
                            >
                              {cell.value.toFixed(2)} %
                            </Text>

                            <Progress
                              variant="table"
                              colorScheme="brandScheme"
                              h="8px"
                              w="13px"
                              value={cell.value}
                            />
                          </Flex>
                        );
                      } else if (cell.column.Header === "INVERTIR") {
                        data = (
                          <Button
                            variant="brand"
                            size="md"
                            onClick={(event) => {
                              event.preventDefault();
                              onOpen();

                              Mixpanel.track('Clic_AbrirCampanya', { 'idCampanya': cell.value });

                              seleccionarYCargarCampanya(cell.value);

                              obtenerInformacionPersona();
                            }}
                          >
                            Invertir
                          </Button>
                        );
                      }
                      return (
                        <Td
                          {...cell.getCellProps()}
                          key={index}
                          fontSize={{ sm: "14px" }}
                          minW={{ sm: "15px", md: "15px", lg: "auto" }}
                          borderColor="transparent"
                        >
                          {data}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Show>
        <Show below="md">
          {
            tableData.map((campanya, index) => (
              <Stack divider={<StackDivider />} spacing='4' px="25px" mb="20px"   >
                <Box>

                  <Heading size='xs' textTransform='uppercase'>
                    <Flex justify="space-between" mb="20px" align="center" >
                      <Text>
                        Préstamo Corto Plazo de {campanya.originador} ({campanya.pais})
                      </Text>

                    </Flex>

                  </Heading>
                  <StatGroup>
                    <Stat>
                      <StatNumber>{campanya.importeSolicititadoString}</StatNumber>
                      <StatHelpText>
                        Importe Solicitado
                      </StatHelpText>
                    </Stat>

                    <Stat px="4px">
                      <StatNumber>{campanya.tasaInteresEAString}</StatNumber>
                      <StatHelpText>
                        Interés
                      </StatHelpText>
                    </Stat>
                    <Stat px="2px">
                      <StatNumber>{campanya.fechaLimiteConFormato}</StatNumber>
                      <StatHelpText>
                        Fecha Límite
                      </StatHelpText>
                    </Stat>
                    <Stat px="4px">
                      <StatNumber>
                        {campanya.porcentajeFinanciadoString}
                        <Progress
                          variant="table"
                          colorScheme="brandScheme"
                          h="8px"
                          w="63px"
                          value={campanya.porcentajeFinanciado}
                        />
                      </StatNumber>
                      <StatHelpText>
                        Progreso
                      </StatHelpText>
                    </Stat>
                    <Stat>
                      <Button
                        variant="brand"
                        size="md"

                        onClick={(event) => {
                          event.preventDefault();
                          onOpen();

                          Mixpanel.track('Clic_AbrirCampanya', { 'idCampanya': campanya.id });

                          seleccionarYCargarCampanya(campanya.id);

                          obtenerInformacionPersona();
                        }}
                      >
                        Invertir
                      </Button>
                    </Stat>
                  </StatGroup>
                </Box>
                <Box>
                  <Heading size='xs' textTransform='uppercase'>
                  </Heading>
                </Box>
              </Stack>
            ))}

        </Show>


        <Modal isOpen={isOpen} onClose={onClose} size="4xl">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Adquiere una participación</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Box pt={{ base: "0px", md: "0px", xl: "0px" }}>
                <SimpleGrid minChildWidth="230px" gap="20px" mb="20px">
                  <MiniStatistics
                    startContent={
                      <IconBox
                        w="56px"
                        h="56px"
                        bg={boxBg}
                        icon={
                          <Icon
                            w="32px"
                            h="32px"
                            as={MdOutlineEuro}
                            color={brandColor}
                          />
                        }
                      />
                    }
                    name="Importe Solicitado"
                    value={campanyaSeleccionada.importeSolicititadoString}
                    growth={campanyaSeleccionada.importeEnEurosString}
                  />
                  <Show above="md" >
                    <MiniStatistics
                      startContent={
                        <IconBox
                          w="56px"
                          h="56px"
                          bg={boxBg}
                          icon={
                            <Icon
                              w="32px"
                              h="32px"
                              as={MdAutoGraph}
                              color={brandColor}
                            />
                          }
                        />
                      }
                      name="Tasa"
                      value={campanyaSeleccionada.tasaInteresEAString}
                    />
                    <MiniStatistics
                      name="Fecha Límite"
                      value={campanyaSeleccionada.fechaLimiteConFormato}
                    />
                  </Show>
                </SimpleGrid>

                <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }}>
                  <SimpleGrid
                    columns={{ base: 1, md: 1, xl: 1 }}
                    gap="20px"
                    mb="20px"
                  >
                    <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px">
                      <Show above="md">
                        <PieCard
                          porcentajefinanciado={porcentajeFinanciado}
                          porcentajeenespera={porcentajeEnEspera}
                        />
                      </Show>
                    </SimpleGrid>
                  </SimpleGrid>
                  <Banner
                    importePendiente={campanyaSeleccionada.importePendiente}
                    importePendienteString={campanyaSeleccionada.importePendienteString}
                    mostrarIrARegistro={mostrarIrARegistro}
                    mostrarVerificarUsuario={mostrarVerificarUsuario}
                    tokenb2m={tokenBit2me}
                    estaVerificado={usuarioEstaVerificado}
                    idCampanya={campanyaSeleccionada.id}
                    tasaCambioUsdt ={tasaCambioUsdt}
                  />
                </SimpleGrid>
                <Flex>&nbsp;</Flex>
                <Accordion defaultIndex={[0]}>
                  <AccordionItem>
                    <h2>
                      <AccordionButton>
                        <Box as="span" flex="1" textAlign="left">
                          <Text
                            color={textColor}
                            fontSize="22px"
                            fontWeight="700"
                            lineHeight="100%"
                          >
                            Detalles Préstamo
                          </Text>
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }}>
                        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }}>
                          <Heading size="sm">Tipo</Heading>
                          <Text>{campanyaSeleccionada.tipoString}</Text>
                          <Heading size="sm">Fecha Inicio</Heading>
                          <Text>
                            {campanyaSeleccionada.fechaInicioPrestamoConFormato}
                          </Text>
                          <Heading size="sm">Fecha Finalización</Heading>
                          <Text>
                            {
                              campanyaSeleccionada.fechaFinalizacionPrestamoConFormato
                            }
                          </Text>
                          <Heading size="sm">Garantía</Heading>
                          <Text>Sí</Text>
                        </SimpleGrid>
                        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }}>
                          <Heading size="sm">Interes en origen</Heading>
                          <Text>
                            {campanyaSeleccionada.tasaInteresEnOrigen} E.M
                          </Text>
                          <Heading size="sm">Importe en origen</Heading>
                          <Text>
                            {campanyaSeleccionada.importeEnMonedaOrigenString} COP
                          </Text>
                          <Heading size="sm">Tasa de cambio a Euro</Heading>
                          <Text>
                            1€ a {campanyaSeleccionada.tasaCambioString}
                          </Text>
                          <Heading size="sm">Tasa de cambio a USDT</Heading>
                          <Text>
                            1 € a {campanyaSeleccionada.tasaCambioStableCoinString}
                          </Text>
                        </SimpleGrid>
                      </SimpleGrid>

                      <Heading size="sm">Descripción</Heading>
                      <Text>{campanyaSeleccionada.descripcion}</Text>
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <h2>
                      <AccordionButton>
                        <Box as="span" flex="1" textAlign="left">
                          <Text
                            color={textColor}
                            fontSize="22px"
                            fontWeight="700"
                            lineHeight="100%"
                          >
                            Calendario de pagos
                          </Text>
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <ColumnsTable
                        columnsData={columnsDataColumns}
                        tableData={pagosCampanyaSeleccionada}
                      />
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </Box>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="brand" mr={3} onClick={onClose}>
                Cerrar
              </Button>
              <Button variant="ghost">Cómo funciona</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Modal isCentered isOpen={isVisiblePopupBono} onClose={onClosePopupBono} size="4xl">
          {overlay}
          <ModalContent>
            <ModalHeader>Bono virtual de bienvenida</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text>Nos alegra que estes abierto a probar el por esa razón queremos hacerte fácil dar el primer paso. Con el bono virtual puedes invertir hasta 100€ sin preocuparte por poner el dinero y los intereses generados podrás usarlos en la siguiente inversión</Text>
              <Text
                fontSize={{ base: "16px", md: "16px" }}
                mt="5px"
                fontWeight="700"
                lineHeight={{ base: "32px", md: "42px" }}
              >
                ¿Cómo funciona?
              </Text>
              <List spacing={3} ml="8px">
                <ListItem>
                  <ListIcon as={CheckCircleIcon} color='green.500' />
                  <NavLink to='/auth/registration' color='teal.500'>
                    Crea tu cuenta
                  </NavLink>
                </ListItem>
                <ListItem>
                  <ListIcon as={CheckCircleIcon} color='green.500' />
                  Añade saldo con el bono de bienvenida en tu <NavLink to='/admin/profile' color='teal.500'>
                    pagina de perfil
                  </NavLink>

                </ListItem>
                <ListItem>
                  <ListIcon as={CheckCircleIcon} color='green.500' />
                  Selecciona los préstamos donde quieres invertir
                </ListItem>
                {/* You can also use custom icons from react-icons */}
                <ListItem>
                  <ListIcon as={CheckCircleIcon} color='green.500' />
                  Recibe mes a mes los intereses generados
                </ListItem>
              </List>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="brand" mr={3} onClick={onClosePopupBono}>
                Cerrar
              </Button>
              <Link ml="10px" variant="ghost" href="https://marketplace.cowallet.app/#/auth/registration" >
                <Text color="black" fontWeight="500">
                  Crear cuenta
                </Text>
              </Link>
              <Link ml="20px" variant="ghost" href="https://www.cowallet.app/career-single/" >
                <Text color="black" fontWeight="500">
                  Más información
                </Text>
              </Link>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Card>
    </Box>

  );
}
