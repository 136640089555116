import React from "react";

import {
  Button,
  ButtonGroup,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  useDisclosure
} from "@chakra-ui/react";

export default function PopoverVerificar() {
  const initialFocusRef = React.useRef();
  const { isOpen, onClose, onOpen } = useDisclosure();

  function irAVerificarB2M()
  { 
    const urlVerify = '#/auth/verify'
    window.location.href = urlVerify;
  }

  return (
    <Popover
      initialFocusRef={initialFocusRef}
      placement="bottom"
      closeOnBlur={false}
      isOpen={isOpen}
    >
      <PopoverTrigger>
        <Button
          bg="white"
          color="black"
          _hover={{ bg: "whiteAlpha.900" }}
          _active={{ bg: "white" }}
          _focus={{ bg: "white" }}
          fontWeight="500"
          fontSize="14px"
          py="20px"
          px="27"
          me="38px"
          onClick={onOpen}
        >
          Verificar
        </Button>
      </PopoverTrigger>
      <PopoverContent color="white" bg="blue.800" borderColor="blue.800">
        <PopoverHeader pt={4} fontWeight="bold" border="0">
          Iniciar proceso de verificación
        </PopoverHeader>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>
          ¿Deseas iniciar en este momento el proceso de verificación de tu
          identidad, para empezar a invertir?
        </PopoverBody>
        <PopoverFooter
          border="0"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          pb={4}
        >
          <ButtonGroup size="sm">
            <Button 
              colorScheme="green"
              onClick={irAVerificarB2M}
            >
              Si
            </Button>
            <Button colorScheme="blue" onClick={onClose} ref={initialFocusRef}>
              Mas Tarde
            </Button>
          </ButtonGroup>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
}
