// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCiftWUAi-yQmWLsqTcSU-HbKhDRNk5uFU",
  authDomain: "prestamos-monolegal.firebaseapp.com",
  projectId: "prestamos-monolegal",
  storageBucket: "prestamos-monolegal.appspot.com",
  messagingSenderId: "1010136414002",
  appId: "1:1010136414002:web:889bc8514f255c6f0f892b"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export default app;
