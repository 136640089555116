import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdCheckCircle,
  MdPerson,
  MdHome,
  MdLock,
} from "react-icons/md";

// Admin Imports
import Profile from "views/admin/profile";
import DataTables from "views/admin/dataTables";
import AuthorizeTransaction from "views/admin/authorize";

// Auth Imports
import SignInCentered from "views/auth/signIn";
import Registration from "views/auth/registration";
import VerifyUser from "views/auth/verification";
import ResetPassword from "views/auth/resetPassword";
import twoFAEnablement from "views/auth/twoFAEnablement";


const routes = [
  {
    name: "Invertir",
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: DataTables,
    IsVisibleInLeftMenu: true,
  },
  {
    name: "Perfil",
    layout: "/admin",
    path: "/profile",
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: Profile,
    IsVisibleInLeftMenu: true,
  },
  {
    name: "Inicia sesión",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: SignInCentered,
    IsVisibleInLeftMenu: true,
  },
  {
    name: "Regístrate",
    layout: "/auth",
    path: "/registration",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: Registration,
    IsVisibleInLeftMenu: true,
  },
  {
    name: "Verificar Usuario",
    layout: "/auth",
    path: "/verify",
    icon: <Icon as={MdCheckCircle} width='20px' height='20px' color='inherit' />,
    component: VerifyUser,
    IsVisibleInLeftMenu: true,
  },
  {
    name: "Activar transferencia segura",
    layout: "/auth",
    path: "/twoFAEnablement",
    icon: <Icon as={MdCheckCircle} width='20px' height='20px' color='inherit' />,
    component: twoFAEnablement,
    IsVisibleInLeftMenu: true,
  },
  {
    name: "Recordar contraseña",
    layout: "/auth",
    path: "/resetPassword",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: ResetPassword,
    IsVisibleInLeftMenu: true,
  },
  {
    name: "Confirmar transacción",
    layout: "/auth",
    path: "/authorize/:id",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: AuthorizeTransaction,
    IsVisibleInLeftMenu: false
  },
];

export default routes;
