import {
    Box,
    Flex,
    Icon,
    Progress,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React from "react";

export default function InformacionWallet(props) {
    const { used, total, importeRecuperado, interesesGanados, saldoDisponible } = props;
    const textColorSecondary = "gray.400";


    return (
        <Card>
            <Box w='100%' mt='auto'>
                <Flex w='100%' justify='space-between' mb='10px'>
                    <Text color={textColorSecondary} maxW='40%'>
                        Saldo
                    </Text>
                    <Text color={textColorSecondary} fontSize='sm' maxW='40%'>
                        {used}
                    </Text>
                </Flex>

            </Box>
            <Box w='100%' >
                <Flex w='100%' justify='space-between' mb='10px'>
                    <Text color={textColorSecondary} maxW='40%'>
                        Importe Invertido
                    </Text>
                    <Text color={textColorSecondary} fontSize='sm' maxW='40%'>
                        {total}
                    </Text>
                </Flex>

            </Box>
            <Box w='100%'>
                <Flex w='100%' justify='space-between' mb='10px'>
                    <Text color={textColorSecondary} maxW='40%'>
                        Importe Recuperado
                    </Text>
                    <Text color={textColorSecondary} fontSize='sm' maxW='40%'>
                        {importeRecuperado}
                    </Text>
                </Flex>
            </Box>
            <Box w='100%'>
                <Flex w='100%' justify='space-between' mb='10px'>
                    <Text color={textColorSecondary} maxW='40%'>
                        Intereses Ganados
                    </Text>
                    <Text color={textColorSecondary} fontSize='sm' maxW='40%'>
                        {interesesGanados} 
                    </Text>
                </Flex>
            </Box>
            <Box w='100%'>
                <Flex w='100%' justify='space-between' mb='10px'>
                    <Text color={textColorSecondary} maxW='40%'>
                        Saldo Disponible
                    </Text>
                    <Text color={textColorSecondary} fontSize='sm' maxW='40%'>
                        {saldoDisponible} 
                    </Text>
                </Flex>
            </Box>
        </Card>
    );
}