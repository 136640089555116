import React, { useState, useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Input,
    Text,
    useColorModeValue,
    Alert,
    AlertIcon,
    AlertDescription,
} from "@chakra-ui/react";
import DefaultAuth from "layouts/auth/Default";
import illustration from "assets/img/auth/auth.png";
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from 'firebaseConfig';
import toast from 'react-simple-toasts';
import { Mixpanel } from 'mixpanel';

function ResetPassword() {
    const textColor = useColorModeValue("navy.700", "white");
    const textColorSecondary = "gray.400";
    const brandStars = useColorModeValue("brand.500", "brand.400");
    const textColorBrand = useColorModeValue("brand.500", "white");

    const history = useHistory();

    const [email, setEmail] = useState('')
    const [showError, setshowError] = useState('hidden');
    const [errorDescription, setErrorDescription] = useState('');

    useEffect(() => {

        Mixpanel.track('Acceso_ResetPassword');
    
      }, []);

    const onSubmit = async (e) => {
        e.preventDefault()
        sendPasswordResetEmail(auth, email)
            .then(() => {
                console.log('Success: Password Reset Email sent.');
                toast("Hemos enviado un correo para que vuelvas a establecer la contraseña. Revisa tu buzón de correo.", { position: 'top-center', duration: 5000 });
                history.push("/auth/sign-in");
            })
            .catch(error => mostraryEstablecerDescripcionDelError(error.message));
    };
    function mostraryEstablecerDescripcionDelError(descripcion) {
        setshowError("visible");
        setErrorDescription(descripcion);
    }


    return (
        <DefaultAuth illustrationBackground={illustration} image={illustration}>
            <Flex
                maxW={{ base: "100%", md: "max-content" }}
                w='100%'
                mx={{ base: "auto", lg: "0px" }}
                me='auto'
                h='100%'
                alignItems='start'
                justifyContent='center'
                mb={{ base: "30px", md: "60px" }}
                px={{ base: "25px", md: "0px" }}
                mt={{ base: "40px", md: "14vh" }}
                flexDirection='column'>
                <Box me='auto'>
                    <Heading color={textColor} fontSize='36px' mb='10px'>
                        Recordar contraseña
                    </Heading>
                    <Text
                        mb='36px'
                        ms='4px'
                        color={textColorSecondary}
                        fontWeight='400'
                        fontSize='md'>
                        Introduce tu email y te enviamos por email un enlace para que vuelvas a establecer la contraseña
                    </Text>
                </Box>
                <Flex
                    zIndex='2'
                    direction='column'
                    w={{ base: "100%", md: "420px" }}
                    maxW='100%'
                    background='transparent'
                    borderRadius='15px'
                    mx={{ base: "auto", lg: "unset" }}
                    me='auto'
                    mb={{ base: "20px", md: "auto" }}>

                    <Flex>
                        <Alert visibility={showError} status="error" variant="left-accent">
                            <AlertIcon></AlertIcon>
                            <AlertDescription>{errorDescription}</AlertDescription>
                        </Alert>
                    </Flex>

                    <FormControl>
                        <FormLabel
                            display='flex'
                            ms='4px'
                            fontSize='sm'
                            fontWeight='500'
                            color={textColor}
                            mb='8px'>
                            Email<Text color={brandStars}>*</Text>
                        </FormLabel>
                        <Input
                            isRequired={true}
                            variant='auth'
                            fontSize='sm'
                            ms={{ base: "0px", md: "0px" }}
                            type='email'
                            placeholder='mail@simmmple.com'
                            mb='24px'
                            fontWeight='500'
                            size='lg'
                            onChange={(e) => setEmail(e.target.value)}
                        />

                        <Button
                            fontSize='sm'
                            variant='brand'
                            fontWeight='500'
                            onClick={onSubmit}
                        >
                            Enviar correo electrónico
                        </Button>
                        <Flex justifyContent='space-between' align='center' mb='24px'>

                            <NavLink to='/auth/sign-in'>
                                <Text
                                    color={textColorBrand}
                                    fontSize='sm'
                                    w='200px'
                                    fontWeight='500'>
                                    Ir a iniciar sesión
                                </Text>
                            </NavLink>
                        </Flex>
                    </FormControl>
                </Flex>
            </Flex>
        </DefaultAuth>
    );

}

export default ResetPassword;