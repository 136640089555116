import React, { useState } from "react";
import { useHistory } from "react-router-dom";
// Chakra imports
import {
  Button,
  Flex,
  Link,
  Text,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
  useColorModeValue,
  Modal,
  useDisclosure,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Box,
  SimpleGrid,
} from "@chakra-ui/react";

// Assets
import banner from "assets/img/nfts/NftBanner1.png";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import PopoverVerificar from "views/admin/marketplace/components/PopoverVerificar";
import { MdAttachMoney, MdAutoGraph } from "react-icons/md";
import { configHeader } from "httpConfig";
import axios from "axios";
import toast from 'react-simple-toasts';

export default function Banner(props) {
  const {
    importePendiente,
    importePendienteString,
    mostrarIrARegistro,
    mostrarVerificarUsuario,
    tokenb2m,
    estaVerificado,
    idCampanya,
    tasaCambioUsdt
  } = props;
  // Chakra Color Mode
  const [sliderValue, setSliderValue] = useState(50);
  const [sliderValueUSDT, setSliderValueUSDT] = useState(0);
  const [importeInvertido, setImporteInvertido] = useState(0);
  const [porcentajeParticipacion, setPorcentajeParticipacion] = useState(0);
  const [interesesGenerados, setInteresesGenerados] = useState(0);
  const [interesesGeneradosEuros, setInteresesGeneradosEuros] = useState(0);
  const [importeInvertidoEnEuros, setImporteInvertidoEnEuros] = useState(0);
  
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const activarBotonInvertir = estaVerificado === true ? "" : true;

  const labelStyles = {
    mt: "2",
    ml: "-2.5",
    fontSize: "sm",
  };
  function irARegistro() {
    history.push("/auth/registration");
  }

  async function crearInversion(esPreview) {
    // Vamos a hacer un http Path para obtener la vista previa pasandole el importe 
    // seleccionado por el usuario

    let importe = sliderValue;

    const urlCampanya =`${process.env.REACT_APP_BASE_API_URL}/Campanyas/${idCampanya}/Participaciones`;

    const header = configHeader();

    const body = JSON.stringify({
      importe: importe,
    });

    if (esPreview) {
      axios
        .patch(urlCampanya, body, header)
        .then((response) => {
          
          let tasaCambio = 1.09;
          let interesesPrevistosEuro = response.data.interesesPrevistos / tasaCambio;

          let interesesPrevistosEuroFormato = interesesPrevistosEuro.toFixed(2).replace(".",",");

          setImporteInvertidoEnEuros(response.data.importeInvertidoEnEurosString);
          setInteresesGeneradosEuros(interesesPrevistosEuroFormato + " €");
          setPorcentajeParticipacion(response.data.porcentajeString);
          setImporteInvertido(response.data.importeInvertidoString);
          setInteresesGenerados(response.data.interesesPrevistosString);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    else {

      axios
        .post(urlCampanya, body, header)
        .then((response) => {

          MostrarToast('Participación creada correctamente');
          history.push("/admin/profile");

        })
        .catch((err) => {
          console.log(err);
          MostrarToast("Error al crear la participación : " + err);
        });
    }

  }

  function MostrarToast(mensaje) {

    toast(mensaje, { position: 'top-center', duration: 5000 });

  }
  function actualizarValoresSlider(val)
  {
    setSliderValue(val);
    let tasaCambio = tasaCambioUsdt;
    let valorEnUSDT = (tasaCambio*val).toFixed(2);
    setSliderValueUSDT(valorEnUSDT);

  }
  return (
    <Flex
      direction="column"
      bgImage={banner}
      bgSize="cover"
      py={{ base: "30px", md: "7px" }}
      px={{ base: "30px", md: "14px" }}
      borderRadius="30px"
    >
      <Text
        fontSize={{ base: "26px", md: "26px" }}
        color="white"
        mb="14px"
        fontWeight="700"
        lineHeight={{ base: "32px", md: "42px" }}
      >
        Adquiere una participación
      </Text>
      <Flex>
        <Text
          fontSize={{ base: "16px", md: "16px" }}
          color="white"
          mb="14px"
          fontWeight="700"
          lineHeight={{ base: "32px", md: "42px" }}
        >
          Quedan {importePendienteString} por financiar
        </Text>
      </Flex>
      <Flex py={{ base: "30px", md: "7px" }}>
        <Slider
          aria-label="slider-ex-1"
          min={5}
          max={importePendiente}
          defaultValue={50}
          onChange={(val) => actualizarValoresSlider(val)}
        >
          <SliderMark
            value={sliderValue}
            textAlign="center"
            bg="blue.500"
            color="white"
            mt="-10"
            ml="-5"
            w="50"
          >
            {sliderValue}€ (≈ {sliderValueUSDT} USDT)
          </SliderMark>
          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>
          <SliderThumb />
        </Slider>
      </Flex>
      <Flex>&nbsp;</Flex>
      <Flex align="center">
        <Button
          bg="white"
          color="black"
          _hover={{ bg: "whiteAlpha.900" }}
          _active={{ bg: "white" }}
          _focus={{ bg: "white" }}
          fontWeight="500"
          fontSize="14px"
          py="20px"
          px="27"
          me="38px"

          onClick={async (event) => {
            event.preventDefault();
            onOpen();
            await crearInversion(true);

          }}
          disabled={activarBotonInvertir}
        >
          Invertir
        </Button>
        <Link href="https://www.cowallet.app/affiliate/" >
          <Text color="white" fontSize="sm" fontWeight="500">
            Cómo Funciona
          </Text>
        </Link>
      </Flex>
      <Flex>&nbsp;</Flex>
      <Flex display={mostrarIrARegistro}>
        <Text color="white">Crea una cuenta para poder invertir</Text>
        <Flex align="center">
          <Button
            bg="white"
            color="black"
            _hover={{ bg: "whiteAlpha.900" }}
            _active={{ bg: "white" }}
            _focus={{ bg: "white" }}
            fontWeight="500"
            fontSize="14px"
            py="20px"
            px="27"
            me="38px"
            onClick={irARegistro}
          >
            Registrate
          </Button>
        </Flex>
      </Flex>
      <Flex align="revert" display={mostrarVerificarUsuario}>
        <Text color="white">
          Antes de continuar es importante que verifiques tu identidad
        </Text>
        <Flex align="center">
          <PopoverVerificar></PopoverVerificar>
        </Flex>
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>A un paso de adquirir tu participación</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box pt={{ base: "0px", md: "0px", xl: "0px" }}>
              Estas a un paso de ganar intereses adquiriendo esta participación
              <SimpleGrid columns={{ base: 3, md: 3, xl: 3 }} >
                <MiniStatistics
                  name="Importe Invertido"
                  value={importeInvertido}
                  growth = {importeInvertidoEnEuros} 
                />
                <MiniStatistics

                  name="Participación"
                  value={porcentajeParticipacion}
                />
                <MiniStatistics
                  name="Intereses generados"
                  value={interesesGenerados}
                  growth = {interesesGeneradosEuros} 
                />
              </SimpleGrid>

            </Box>

          </ModalBody>
          <ModalFooter>

            <Button colorScheme="brand" mr={3}
              onClick={async (event) => {
                event.preventDefault();
                onOpen();
                await crearInversion(false);

              }}
            >
              Adquirir
            </Button>
            <Button variant="ghost" onClick={onClose}>Cerrar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}
