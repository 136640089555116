// Chakra imports
import { Avatar, Box, Flex, Text, useColorModeValue, Button } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React from "react";
import IrAVerificar from "views/admin/profile/components/IrAVerificar";

export default function Banner(props) {
  const { banner, avatar, name, job, saldo, estaVerificado, abrirAnyadirTarjeta, tieneTarjeta } = props;

  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const borderColor = useColorModeValue(
    "white !important",
    "#111C44 !important"
  );

  return (
    <Card mb={{ base: "0px", lg: "20px" }} align='center'>
      <Box
        bg={`url(${banner})`}
        bgSize='cover'
        borderRadius='16px'
        h='131px'
        w='100%'
      />
      <Avatar
        mx='auto'
        src={avatar}
        h='87px'
        w='87px'
        mt='-43px'
        border='4px solid'
        borderColor={borderColor}
      />
      <Text color={textColorPrimary} fontWeight='bold' fontSize='xl' mt='10px'>
        {name}
      </Text>
      <Text color={textColorSecondary} fontSize='sm'>
        {job}
      </Text>
      {
        estaVerificado === true ?
          (
            <Flex w='max-content' mx='auto' mt='26px' >
              <Flex mx='auto' me='60px' align='center' direction='column'>
                <Text color={textColorPrimary} fontSize='2xl' fontWeight='700'>
                  OK
                </Text>
                <Text color={textColorSecondary} fontSize='sm' fontWeight='400'>
                  Verificado
                </Text>
              </Flex>
              {tieneTarjeta === true ? (
                <Flex mx='auto' me='60px' align='center' direction='column'>
                  <Text color={textColorPrimary} fontSize='2xl' fontWeight='700'>
                    OK
                  </Text>
                  <Text color={textColorSecondary} fontSize='sm' fontWeight='400'>
                    Tarjeta
                  </Text>
                </Flex>
              ) :
                (
                  <Flex mx='auto' me='60px' align='center' direction='column'>
                    <Button
                      variant="brand"
                      size="md"
                      mt='15px'
                      onClick={(event) => {
                        event.preventDefault();
                        props.abrirAnyadirTarjeta();
                      }}
                    >
                      Añadir Tarjeta
                    </Button>
                  </Flex>
                )
              }

              <Flex mx='auto' align='center' direction='column'>
                <Text color={textColorPrimary} fontSize='2xl' fontWeight='700'>
                  {saldo}
                </Text>
                <Text color={textColorSecondary} fontSize='sm' fontWeight='400'>
                  Saldo
                </Text>
              </Flex>
            </Flex>
          )
          :
          //En el caso de que esté verficado 
          <IrAVerificar />
      }


    </Card>
  );
}
