import DefaultAuth from "layouts/auth/Default";
import illustration from "assets/img/auth/auth.png";
import {
    Flex,
    Box,
    Heading,
    useColorModeValue,
    StatGroup,
    Stat,
    StatLabel,
    StatNumber,
    StatHelpText,
    StatArrow,
    Text,
    Button,
    FormControl,
    FormLabel,
    Input,
    Alert,
    AlertIcon,
    AlertDescription,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import toast from 'react-simple-toasts';
import axios from "axios";
import { configHeader } from "httpConfig";

function AuthorizeTransaction() {
    const { id } = useParams();
    const textColor = useColorModeValue("navy.700", "white");
    const textColorSecondary = "gray.400";
    const tituloPaso1 = "Iniciar transferencia";
    const descripcionPaso1 = "Te enviaremos un código a tu telefono móvil o email el cual debes enviarnoslo en el siguiente paso";

    const tituloPaso2 = "Envíanos el código de Google Athenticator";
    const descripcionPaso2 = "Abre la aplicación Google autheticator. Envíanos el código que se muestra en pantalla para confirmar que deseas hacer la transferencia";
  
    const msgErrorXtotp = "Es obligatorio poner el código de verificación";
  
    const [nombreOriginador, setNombreOriginador] = useState("Prami prestamos S.A.S");
    const [fechaFinalizacion, setFechaFinalizacion] = useState("10/12/2023");
    const [importeSolicitado, setImporteSolicitado] = useState();
    const [porcentajeParticipacion, setPorcentajeParticipacion] = useState();
    const [importeParticipacion, setImporteParticipacion] = useState();
    const [interesesPrevistos, setInteresesPrevistos] = useState();
    const [tasaInteres, setTasaInteres] = useState();

    const [tieneTransferencia, setTieneTransferencia] = useState(false);
    const [fechaExpiracionTransaferencia, setFechaExpiracionTransaferencia] = useState(false);

    const [tituloPaso, setTituloPaso] = useState(tituloPaso1);
    const [descripcionPaso, setDescripcionPaso] = useState(descripcionPaso1);
    const [mostrarPaso1, setMostrarPaso1] = useState(true);
    const [mostrarPaso2, setMostrarPaso2] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [configHttp, setConfigHttp] = useState();
    const [formValues, setFormValues] = useState({
        totp: "",
    });
    const history = useHistory();
    const brandStars = useColorModeValue("brand.500", "brand.400");

    useEffect(() => {
        setFormErrors({});
        const header = configHeader();
        setConfigHttp(header);
    }, []);
    useEffect(() => {
        if (configHttp != undefined) {
            obtenerParticipacion();
            obtenerPersona();
        }
    }, [configHttp]);
    useEffect(() => {
        if (tieneTransferencia != undefined && tieneTransferencia == true) {
            VisualizarPaso2();
        }
    }, [tieneTransferencia]);

    function VisualizarPaso2() {
        setTituloPaso(tituloPaso2);
        setDescripcionPaso(descripcionPaso2);
        setMostrarPaso1(false);
        setMostrarPaso2(true);
    }
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };
    async function iniciarTransferencia() {
        const dto = JSON.stringify({});
        await axios
            .post(
                process.env.REACT_APP_BASE_API_URL + "/MisParticipaciones/" + id + "/TransferenciaOriginador",
                dto,
                configHttp
            )
            .then((response) => {
                setTieneTransferencia(true);
                setFechaExpiracionTransaferencia(response.data.fechaExpiracionTransferencia);
                VisualizarPaso2();
            })
            .catch((err) => {
                if (err.request.status === 401) {
                    RedirigirALogin();
                } else {
                    setUnexpectedErrorMessage();
                }
            });
    }

    async function obtenerPersona() {
        await axios
            .get(process.env.REACT_APP_BASE_API_URL + "/Personas?iniciarKYC=false", configHttp)
            .then((response) => {
                setFormErrors({});
                if (response.data.noExiste) {
                    MostrarToast("Por favor verifique su identidad antes de continuar");
                    history.push("/admin/default");
                    return;
                }

                if (!response.data.tieneActivaAutenticacion2FA) {
                    MostrarToast("Por favor activa las transferencias seguras para confirmar la transferencia");
                    history.push("/auth/twoFAEnablement");
                    return;
                }

            })
            .catch((err) => {
                if (err.request.status === 401) {
                    RedirigirALogin();
                    return;
                } else {
                    setUnexpectedErrorMessage();
                }
            });
    }

    async function obtenerParticipacion() {
        await axios
            .get(process.env.REACT_APP_BASE_API_URL + "/MisParticipaciones/" + id + "?conInfoTransferencia=true", configHttp)
            .then((response) => {
                setFormErrors({});
                setNombreOriginador(response.data.campanya.originador);
                setFechaFinalizacion(response.data.campanya.fechaFinalizacionPrestamoConFormato);
                setImporteSolicitado(response.data.campanya.importeSolicititadoString);
                setPorcentajeParticipacion(response.data.porcentajeString);
                setImporteParticipacion(response.data.importeInvertidoString);
                setInteresesPrevistos(response.data.interesesPrevistosString);
                setTasaInteres(response.data.campanya.tasaInteresEAString);

                setTieneTransferencia(response.data.movimientoFondos.tieneTransferencia);
                setFechaExpiracionTransaferencia(response.data.movimientoFondos.fechaExpiracionTransferencia);


            })
            .catch((err) => {
                if (err.request.status === 401) {
                    MostrarToast("Porfa inicia sesión o regístrate para poder continuar");
                    history.push("/auth/sign-in");
                    return;
                } else {
                    setUnexpectedErrorMessage();
                }
            });

    }
    const validateForm = () => {
        let errors = {};
        if (!formValues.xtotp) {

            errors.xtotp = msgErrorXtotp;
        }
        setFormErrors(errors);

        return Object.keys(errors).length === 0;
    }
    const onEnviarCodigoVerificacion = async (e) => {
        e.preventDefault();
        if (!validateForm()) {
            return;
        }

        const dto = JSON.stringify({
            totp: formValues.xtotp,
        });

        console.log(dto);

        await axios
            .put(
                process.env.REACT_APP_BASE_API_URL + "/MisParticipaciones/" + id + "/TransferenciaOriginador",
                dto,
                configHttp
            )
            .then((response) => {
                MostrarToast("Transferencia confirmada correctamente. Gracias!");
                history.push("/admin/profile");
            })
            .catch((err) => {
                if (err.request.status === 401) {
                    RedirigirALogin();
                } else {
                    setUnexpectedErrorMessage();
                }
            });
    }
    function MostrarToast(mensaje) {

        toast(mensaje, { position: 'top-center', duration: 5000 });

    }

    function RedirigirALogin() {
        MostrarToast("Porfa inicia sesión o regístrate para poder continuar");
        history.push("/auth/sign-in");
    }

    function setUnexpectedErrorMessage() {
        setFormErrors({
            unexpected_error: (
                <Text>
                    Lo sentimos, se ha presentado un error inesperado en el sistema. Por
                    favor, contactenos para dar una pronta solución.
                </Text>
            ),
        });
    }

    return (
        <DefaultAuth illustrationBackground={illustration} image={illustration}>
            <Box p={{ base: "10%", xl: "0%", lg: "0%" }} mb="10%">
                <Heading color={textColor} fontSize="36px" mb="10px">
                    Confirma la transferencia
                </Heading>
                <Flex w={{ base: "100%", xl: "40%", lg: "40%" }}>
                    <Text

                        mb="12px"
                        mt="24px"
                        color={textColorSecondary}
                        fontWeight="400"
                        fontSize="md"
                    >
                        Por favor confirma la transferencia al originador <b>{nombreOriginador}</b>  de tu participación cuya fecha de
                        finalización es <b>{fechaFinalizacion}</b>. Este procedimiento es necesario para asegurar que
                        solo tu autorizas el movimiento de fondos y así mantener los estándares de seguridad.
                    </Text>
                </Flex>
                <Flex mt="2%" w={{ base: "100%", md: "95%", xl: "45%", lg: "45%" }} >
                    <StatGroup>
                        <Stat>
                            <StatLabel>Participación</StatLabel>
                            <StatNumber>{porcentajeParticipacion}</StatNumber>
                            <StatHelpText>

                                de {importeSolicitado}
                            </StatHelpText>
                        </Stat>

                        <Stat w='250px'>
                            <StatLabel  >Importe a transferir</StatLabel>
                            <StatNumber>{importeParticipacion}</StatNumber>

                        </Stat>
                        <Stat w='250px'>
                            <StatLabel  >Interes previsto</StatLabel>
                            <StatNumber>{interesesPrevistos}</StatNumber>
                            <StatHelpText>
                                <StatArrow type='increase' />
                                al {tasaInteres} E.A*
                            </StatHelpText>
                        </Stat>
                    </StatGroup>
                </Flex>
                <Flex direction='column'>
                    <Text
                        color={textColor}
                        fontSize={{
                            base: "xl",
                            md: "lg",
                            lg: "lg",
                            xl: "lg",
                            "2xl": "md",
                            "3xl": "lg",
                        }}
                        mb='5px'
                        mt='15px'
                        fontWeight='bold'
                        me='14px'>
                        {tituloPaso}
                    </Text>
                    <Text
                        color='secondaryGray.600'
                        fontSize={{
                            base: "sm",
                        }}
                        w={{ base: "100%", md: "45%" }}
                        fontWeight='400'
                        me='14px'>
                        {descripcionPaso}
                    </Text>
                </Flex>
                <Flex
                    zIndex="2"
                    direction="column"
                    w={{ base: "100%", md: "420px" }}
                    maxW="100%"
                    background="transparent"
                    borderRadius="15px"
                    mx={{ base: "auto", lg: "unset" }}
                    me="auto"
                    mt="15px"
                    mb={{ base: "20px", md: "auto" }}
                    align={"center"}>
                    <Flex display={mostrarPaso1 ? "flex" : "none"}>
                        <Button
                            fontSize="sm"
                            variant="brand"
                            fontWeight="500"
                            onClick={iniciarTransferencia}
                        >
                            Iniciar transferencia
                        </Button>
                    </Flex>
                    <Flex display={mostrarPaso2 ? "flex" : "none"}
                        flexDirection="column">
                        <Flex
                            direction="column"
                            w={{ base: "100%", md: "420px" }}
                            maxW="100%"
                        >
                            {Object.values(formErrors).map((error) => {
                                return (
                                    <Alert
                                        key={"_alert_" + error}
                                        status="error"
                                        variant="left-accent"
                                    >
                                        <AlertIcon></AlertIcon>
                                        <AlertDescription>{error}</AlertDescription>
                                    </Alert>
                                );
                            })}
                        </Flex>
                        <FormControl>
                            <FormLabel
                                display="flex"
                                ms="4px"
                                fontSize="sm"
                                fontWeight="500"
                                color={textColor}
                                mb="8px"
                                mt="20px"
                            >
                                Código Verificación <Text color={brandStars}>*</Text>
                            </FormLabel>
                            <Input
                                isRequired={true}
                                variant="auth"
                                fontSize="sm"
                                ms={{ base: "0px", md: "0px" }}
                                type="text"
                                mb="24px"
                                fontWeight="500"
                                size="lg"
                                name="xtotp"
                                value={formValues.xtotp}
                                onChange={handleChange}
                            />
                            <Button
                                fontSize="sm"
                                variant="brand"
                                fontWeight="500"
                                onClick={onEnviarCodigoVerificacion}
                            >
                                Enviar
                            </Button>
                        </FormControl>
                    </Flex>
                </Flex>

            </Box>

        </DefaultAuth>
    )
}

export default AuthorizeTransaction;