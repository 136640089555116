export const columnsDataDevelopment = [
  {
    Header: "País",
    accessor: "pais",
  },
  {
    Header: "Originador",
    accessor: "originador",
  },
  {
    Header: "Interés",
    accessor: "tasaInteresEAString",
  },
  {
    Header: "Fecha Límite",
    accessor: "fechaLimiteConFormato",
  },
  {
    Header: "Importe (€)",
    accessor: "importeEnEurosString",
  },
  {
    Header: "Importe",
    accessor: "importeSolicititadoString",
  },
  
    {
    Header: "Tipo",
    accessor: "tipoString",
  },  
  {
    Header: "Progreso",
    accessor: "porcentajeFinanciado",
  },
  {
    Header: "INVERTIR",
    accessor: "id",
  },
];

export const columnsDataCheck = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];

export const columnsDataColumns = [
  {
    Header: "Fecha",
    accessor: "fechaVencimientoConFormato",
  },
  {
    Header: "Capital",
    accessor: "capitalString",
  },
  {
    Header: "Interes",
    accessor: "interesString",
  },
  {
    Header: "Total",
    accessor: "totalString",
  },
  {
    Header: "Estado",
    accessor: "estado",
  },
];

export const columnsDataComplex = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
];
