/* eslint-disable */
import React, { useState, useEffect } from "react";
// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Text,
  useColorModeValue,
  Alert,
  AlertIcon,
  AlertDescription,
  Spinner,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
// Custom components
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.png";
import { auth } from "firebaseConfig";
import { onAuthStateChanged } from "firebase/auth";
import axios from "axios";
import PhoneInput, {
  isValidPhoneNumber,
  parsePhoneNumber,
} from "react-phone-number-input";
import es from "react-phone-number-input/locale/es";
import "react-phone-number-input/style.css";
import { configHeader } from "httpConfig";
import { useHistory } from "react-router-dom";
import toast from 'react-simple-toasts';

function VerifyUser() {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const brandStars = useColorModeValue("brand.500", "brand.400");

  const [configHttp, setConfigHttp] = useState();

  const [formValues, setFormValues] = useState({
    name: "",
    lastName: "",
    email: "",
    countryCode: "34",
    phone: "",
  });

  const [formErrors, setFormErrors] = useState({});
  const [viewForm, setViewForm] = useState(false);
  const [viewRedirectInit, setViewRedirectInit] = useState(false);
  const [viewSessionInfo, setViewSessionInfo] = useState(true);
  const [viewSpinner, setViewSpinner] = useState(true);
  const [urlPopupKYC, setUrlPopupKYC] = useState();
  const [mostrarBotonAbrirVerificacion, setMostrarBotonAbrirVerificacion] = useState(false);

  const history = useHistory();
  const { isOpen: isOpenPopupKYC, onOpen: onOpenPopupKYC, onClose: onClosePopupKYC } = useDisclosure()

  useEffect(() => {
    setFormErrors({});
    const header = configHeader();
    setConfigHttp(header);
  }, []);

  useEffect(() => {
    if (configHttp != undefined) {
      obtenerPersona();
    }
  }, [configHttp]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  function setPhone(value) {
    const event = {
      target: {
        name: "phone",
        value: value,
      },
    };

    handleChange(event);
  }

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    const phoneNumber = parsePhoneNumber(formValues.phone);

    const persona = JSON.stringify({
      telefono: phoneNumber.nationalNumber,
      codigoPais: phoneNumber.countryCallingCode,
      nombre: formValues.name,
      apellidos: formValues.lastName,
    });

    console.log(persona);
    await crearPersona(persona);
  };

  const validateForm = () => {
    console.log(formValues);
    let errors = {};

    if (!formValues.name) {
      errors.name = "El nombre es un campo requerido";
    }

    if (!formValues.lastName) {
      errors.lastName = "El Apellido es un campo requerido";
    }

    if (!formValues.phone) {
      errors.phone = "El número de teléfono es requerido";
    } else if (isValidPhoneNumber(formValues.phone) === false) {
      errors.phone =
        "El número de teléfono ingresado no es válido en el país seleccionado";
    }

    setFormErrors(errors);

    return Object.keys(errors).length === 0;
  };

  async function crearPersona(persona) {
    setViewForm(false);
    setViewSpinner(true);
    await axios
      .post(
        process.env.REACT_APP_BASE_API_URL + "/Personas",
        persona,
        configHttp
      )
      .then((response) => {
        navegarABit2Me(response.data.urLKyc);
      })
      .catch((err) => {
        if (err.request.status === 401) {
          setSessionErrorMessage();
        } else {
          setUnexpectedErrorMessage();
        }
      });
  }

  function AbrirManualmentePopupVerificacion() {
    setMostrarBotonAbrirVerificacion(false);
    setTimeout(() => navegarABit2Me(urlPopupKYC), 1000);

  }
  function CerrarPopupYEvaluarKYCEnBit2me() {
    onClosePopupKYC();
    axios
      .put(
        process.env.REACT_APP_BASE_API_URL + "/Personas/ActualizarKYC", null,
        configHttp
      )
      .then((response) => {

        if (response.data.tieneKYC) {
          MostrarToast("¡La verificación de la identidad se realizó correctamente, gracias!");

          history.push("/admin/default");
          return;
        }

        if (!response.data.tieneKYC && response.data.conErrorKYC) {
          setUrlPopupKYC(response.data.urLKyc);
          setErroKYCErrorMessage();
          return;
        }

        setUrlPopupKYC(response.data.urLKyc);
        setMostrarBotonAbrirVerificacion(true);
      })
      .catch((err) => {

        MostrarToast("Ha ocurrido un error al actualizar el estado de KYC, por favor, ponte en contacto con nosotros por el chat.");
        console.log(err);

      });
  }

  async function obtenerPersona() {
    await axios
      .get(process.env.REACT_APP_BASE_API_URL + "/Personas?iniciarKYC=true", configHttp)
      .then((response) => {
        setFormErrors({});
        setViewSessionInfo(false);
        if (response.data.noExiste) {
          setViewForm(true);
          setViewSpinner(false);
          getUserSessionData();
        } else {

          if(response.data.tieneKYC)
          {
            setMensajeYaTieneKYC();
            return;
          }

          if (!response.data.tieneKYC && response.data.kycIniciado) {
            // Si existe la persona y ya esta iniciado el KYC
            setUrlPopupKYC(response.data.urLKyc);
            navegarABit2Me(response.data.urLKyc);
          }

        }
      })
      .catch((err) => {
        if (err.request.status === 401) {
          setSessionErrorMessage();
        } else {
          setUnexpectedErrorMessage();
        }
      });
  }

  function navegarABit2Me(url) {

    setViewRedirectInit(true);
    setFormErrors({
      sucess_sesion: (
        <Text>
          A continuación, se abrirá una pestaña para continuar con el proceso de
          verificación (KYC).
        </Text>
      ),
    });

    onOpenPopupKYC();
    console.log(url);
    document.getElementById('frameKYCBit2me').src = url;

  }

  function getUserSessionData() {
    onAuthStateChanged(auth, (user) => {
      setFormValues({
        ...formValues,
        ["name"]: user.displayName ?? "",
        ["email"]: user.email,
      });
    });
  }

  function setUnexpectedErrorMessage() {
    setViewSpinner(false);
    setViewSessionInfo(true);
    setFormErrors({
      unexpected_error: (
        <Text>
          Lo sentimos, se ha presentado un error inesperado en el sistema. Por
          favor, contactenos para dar una pronta solución.
        </Text>
      ),
    });
  }

  function setSessionErrorMessage() {
    setViewSpinner(false);
    setViewSessionInfo(true);
    setFormErrors({
      error_sesion: (
        <Text>
          Tu sesión ha caducado. Por favor, inicia sesión{" "}
          <Link href="#/auth/sign-in">
            <b>aquí</b>
          </Link>
        </Text>
      ),
    });
  }
   function setMensajeYaTieneKYC() {
    setViewSpinner(false);
    setViewSessionInfo(true);
    setViewRedirectInit(true);
    setFormErrors({
      sucess_sesion: (
        <Text>
          Tu identidad ya fue verificada. Comienza a invertir haciendo clic &nbsp;
          <Link href="#/admin/default">
            <b>aquí</b>
          </Link>
        </Text>
      ),
    });
  }
  
  function setErroKYCErrorMessage() {
    setViewSpinner(false);
    setViewSessionInfo(true);
    setViewRedirectInit(false);
    setFormErrors({
      unexpected_error: (
        <Text>
          Lo sentimos, ocurrió un error al realizar la verificación, por favor,
          ponte en contacto con nosotros por medio del chat o al correo diego@cowallet.app
        </Text>
      ),
    });
  }
  function MostrarToast(mensaje) {

    toast(mensaje, { position: 'top-center', duration: 5000 });

  } 

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px">
            Registrar datos para verificación
          </Heading>
          <Text
            w={{ base: "100%", md: "45%" }}
            mb="12px"
            mt="24px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Para garantizar la seguridad y protección de todos nuestros
            usuarios, es necesario que verifiquemos su identidad (KYC), que nos
            permite confirmar que sus actividades en nuestra plataforma son
            legítimas y cumplir con las regulaciones vigentes en materia de
            prevención de fraudes y lavado de dinero.
          </Text>
        </Box>
        <Flex
          display={
            viewSessionInfo || viewForm || viewRedirectInit ? "flex" : "none"
          }
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
        >
          {Object.values(formErrors).map((error) => {
            return (
              <Alert
                key={"_alert_" + error}
                status={viewRedirectInit ? "success" : "error"}
                variant="left-accent"
              >
                <AlertIcon></AlertIcon>
                <AlertDescription>{error}</AlertDescription>
              </Alert>
            );
          })}
        </Flex>
        <Flex
          display={viewForm ? "flex" : "none"}
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <FormControl>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
              mt="20px"
            >
              Nombres<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="text"
              mb="24px"
              fontWeight="500"
              size="lg"
              name="name"
              value={formValues.name}
              onChange={handleChange}
            />
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Apellidos<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="text"
              mb="24px"
              fontWeight="500"
              size="lg"
              name="lastName"
              value={formValues.lastName}
              onChange={handleChange}
            />
            <FormLabel
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              display="flex"
            >
              Teléfono<Text color={brandStars}>*</Text>
            </FormLabel>
            <Flex
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="text"
              mb="24px"
              fontWeight="500"
              size="lg"
            >
              <PhoneInput
                international={false}
                defaultCountry="ES"
                labels={es}
                onChange={(e) => setPhone(e)}
              />
            </Flex>
            <Button
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              onClick={onSubmit}
            >
              Realizar Validación
            </Button>
          </FormControl>
        </Flex>
        <Flex
          display={viewSpinner ? "flex" : "none"}
          direction="column"
          align="center"
          justifyContent="center"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
          mt="20px"
        >
          <Flex
            display={!mostrarBotonAbrirVerificacion ? "flex" : "none"}
            direction="column"
            align="center">
            <Text>Espera un momento ...</Text>
            <Spinner
              mt="20px"
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </Flex>

          <Flex
            display={mostrarBotonAbrirVerificacion ? "flex" : "none"}
            direction="column"
            align="center"
          >
            <Text>
              Volver a iniciar el proceso de verificación de tu identidad
            </Text>
            <Button
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              onClick={AbrirManualmentePopupVerificacion}
            >
              Abrir verificación
            </Button>
          </Flex>
        </Flex>

      </Flex>
      <Modal isOpen={isOpenPopupKYC} onOpen={onOpenPopupKYC} onClose={CerrarPopupYEvaluarKYCEnBit2me} size="4xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Verifica tu identidad</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex >
              <iframe
                title="Grid Generator"
                id="frameKYCBit2me"
                src=""
                width="100%"
                height="500px"
              />
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </DefaultAuth>
  );
}

export default VerifyUser;
