// Chakra imports
import { Box, SimpleGrid } from "@chakra-ui/react";
import DevelopmentTable from "views/admin/dataTables/components/DevelopmentTable";

import { columnsDataDevelopment } from "views/admin/dataTables/variables/columnsData";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Mixpanel } from 'mixpanel';
export default function Settings() {
  const [posts, setPosts] = useState([]);

  useEffect(() => {

    Mixpanel.track('Acceso_Invertir');

    const config = {
      headers: {
        Authorization: "apiKey 1234",
      },
    };

    axios
      .get(process.env.REACT_APP_BASE_API_URL + "/Campanyas", config)
      .then((response) => {
        setPosts(response.data);
        console.log(
          "Entorno Usado: " +
            process.env.REACT_APP_ENVIRONMENT +
            ". \nAPI_URL: " +
            process.env.REACT_APP_BASE_API_URL
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // Chakra Color Mode
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid minChildWidth="120px" spacing="40px">
        <DevelopmentTable
          columnsData={columnsDataDevelopment}
          tableData={posts}
        />
      </SimpleGrid>
    </Box>
  );
}
