export const LAYOUTS_ROUTES = {
    admin: "/admin",
    auth: "/auth",
    rtl: "/rtl"
}

export const ADMIN_LAYOUTS_ROUTES = {
    detalleDelExpediente: "/DetalleDelExpediente"
}

export const ADMIN_LAYOUTS_TITLES = {
    detalleDelExpediente: "Detalle del Expediente"
}