export const columnasParticipaciones = [
    {
        Header: "Participación",
        accessor: "porcentajeString",
    },
    {
        Header: "Valor Invertido",
        accessor: "importeInvertidoString",
    },
    {
        Header: "Progreso",
        accessor: "id",
    },
];

export const columnasTransacciones = [
    {
        Header: "Fecha",
        accessor: "fechaString",
    },
    {
        Header: "Descripción",
        accessor: "descripcion",
    },
    {
        Header: "Importe",
        accessor: "importeString",
    },
    {
        Header: "Saldo",
        accessor: "saldoString",
    },
];