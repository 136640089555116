// Chakra imports
import { Box, Flex, Text, Select, useColorModeValue } from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import PieChart from "components/charts/PieChart";
import { pieChartData, pieChartOptions } from "variables/charts";
import { VSeparator } from "components/separator/Separator";
import React,  { useState }from "react";

export default function Conversion(props) {
  const { ...rest } = props;
  const { porcentajefinanciado, porcentajeenespera } = props;
  const [porcentajeFinanciado, setPorcentajeFinanciado] = useState(porcentajefinanciado);
  const [porcentajeEnEspera, setPorcentajeEnEspera] = useState(porcentajeenespera);
  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const cardColor = useColorModeValue("white", "navy.700");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  return (
    <Card p='20px' align='center' direction='column' w='100%' {...rest}>

      <PieChart
        h='100%'
        w='100%'
        chartData={[porcentajeFinanciado,porcentajeEnEspera,100]}
        chartOptions={pieChartOptions}
      />
      <Card
        bg={cardColor}
        flexDirection='row'
        boxShadow={cardShadow}
        w='100%'
        p='10px'
        px='20px'
        mt='15px'
        mx='auto'>
        <Flex direction='column' py='10px'>
          <Flex align='center'>
            <Box h='8px' w='8px' bg='brand.500' borderRadius='50%' me='4px' />
            <Text
              fontSize='xs'
              color='secondaryGray.600'
              fontWeight='700'
              mb='5px'>
              Financiado
            </Text>
          </Flex>
          <Text fontSize='lg' color={textColor} fontWeight='700'>
            {porcentajefinanciado}%
          </Text>
        </Flex>
        <VSeparator mx={{ base: "60px", xl: "60px", "2xl": "60px" }} />
        <Flex direction='column' py='5px' me='10px'>
          <Flex align='center'>
            <Box h='8px' w='8px' bg='#6AD2FF' borderRadius='50%' me='4px' />
            <Text
              fontSize='xs'
              color='secondaryGray.600'
              fontWeight='700'
              mb='5px'>
              En espera
            </Text>
          </Flex>
          <Text fontSize='lg' color={textColor} fontWeight='700'>
            {porcentajeenespera}%
          </Text>
        </Flex>
      </Card>
    </Card>
  );
}
