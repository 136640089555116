import DefaultAuth from "layouts/auth/Default";
import illustration from "assets/img/nfts/Nft2.png";
import illustrationPaso2 from "assets/img/nfts/Nft3.png";
import illustrationPaso3 from "assets/img/nfts/Nft4.png";
import React, { useState, useEffect } from "react";
import { configHeader } from "httpConfig";
import { useHistory } from "react-router-dom";
import toast from 'react-simple-toasts';
import axios from "axios";
import {
    Flex,
    Box,
    Heading,
    Text,
    useColorModeValue,
    OrderedList,
    ListItem,
    Button,
    FormControl,
    FormLabel,
    Input,
    Alert,
    AlertIcon,
    AlertDescription,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Image,
    SimpleGrid,
    ModalFooter
} from "@chakra-ui/react";

function TwoFAEnablement() {
    // Chakra color mode
    const textColor = useColorModeValue("navy.700", "white");
    const textColorSecondary = "gray.400";
    const tituloPaso1 = "Solicitar código de verificación";
    const descripcionPaso1 = "Te enviaremos un código a tu telefono móvil o email el cual debes enviarnoslo en el siguiente paso";

    const tituloPaso2 = "Configurar Google Authenticator";
    const descripcionPaso2 = "Envianos el código que recibiste y te mostramos el código QR para configurar facilmente Google Authenticator";

    const tituloPaso3 = "Envíanos un código de Google Authenticator";
    const descripcionPaso3 = "Abre la aplicación Google Authenticator y envíanos el código que se muestra en pantalla.";

    const msgErrorXtotp = "Es obligatorio poner el código de verificación";

    const [imagenDerecha, setimagenDerecha] = useState(illustration);
    const [mostrarPaso1, setMostrarPaso1] = useState(true);
    const [mostrarPaso2, setMostrarPaso2] = useState(false);
    const [mostrarPaso3, setMostrarPaso3] = useState(false);

    const [qrConfiguracion, setqrConfiguracion] = useState();
    const [secretConfiguracion, setSecretConfiguracion] = useState();

    const [tituloPaso, setTituloPaso] = useState(tituloPaso1);
    const [descripcionPaso, setDescripcionPaso] = useState(descripcionPaso1);
    const [totptype, setTotptype] = useState(1);
    const [formErrors, setFormErrors] = useState({});
    const [configHttp, setConfigHttp] = useState();
    const [formValues, setFormValues] = useState({
        totp: "",
    });
    const { isOpen: isOpenPopupQRConfiguracion, onOpen: onOpenPopupQRConfiguracion, onClose: onClosePopupQRConfiguracion } = useDisclosure()

    const history = useHistory();
    const brandStars = useColorModeValue("brand.500", "brand.400");

    useEffect(() => {
        setFormErrors({});
        const header = configHeader();
        setConfigHttp(header);
    }, []);

    useEffect(() => {
        if (configHttp != undefined) {
            obtenerPersona();
        }
    }, [configHttp]);

    async function obtenerPersona() {
        await axios
            .get(process.env.REACT_APP_BASE_API_URL + "/Personas?iniciarKYC=false", configHttp)
            .then((response) => {
                setFormErrors({});
                if (response.data.noExiste) {
                    MostrarToast("Por favor verifique su identidad antes de continuar");
                    history.push("/admin/default");
                    return;
                }

                if (response.data.tieneActivaAutenticacion2FA) {
                    MostrarToast("¡Ya tienes activa las transacciones seguras, gracias!");
                    history.push("/admin/profile");
                    return;
                }

                if (response.data.tieneConfiguracion2FA) {

                    MostrarPaso3(response.data.qrConfigurarGoogle2FA, response.data.secretConfigurarGoogle2FA);

                    return;
                }
            })
            .catch((err) => {
                if (err.request.status === 401) {
                    RedirigirALogin();
                    return;
                } else {
                    setUnexpectedErrorMessage();
                }
            });
    }

    function MostrarPaso2() {
        setTituloPaso(tituloPaso2);
        setDescripcionPaso(descripcionPaso2);
        setimagenDerecha(illustrationPaso2);
        setMostrarPaso1(false);
        setMostrarPaso2(true);
        setMostrarPaso3(false);
    }

    function setUnexpectedErrorMessage() {
        setFormErrors({
            unexpected_error: (
                <Text>
                    Lo sentimos, se ha presentado un error inesperado en el sistema. Por
                    favor, contactenos para dar una pronta solución.
                </Text>
            ),
        });
    }
    function MostrarToast(mensaje) {

        toast(mensaje, { position: 'top-center', duration: 5000 });

    }
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };
    const validateForm = () => {
        let errors = {};
        if (!formValues.xtotp) {

            errors.xtotp = msgErrorXtotp;
        }
        setFormErrors(errors);

        return Object.keys(errors).length === 0;
    }

    const validateFormFinalizar = () => {
        let errors = {};
        if (!formValues.xtotp1) {

            errors.xtotp1 = msgErrorXtotp;
        }
        setFormErrors(errors);

        return Object.keys(errors).length === 0;
    }

    const onFinalizarVerificacion = async (e) => {
        console.log("holaa");
        e.preventDefault();
        if (!validateFormFinalizar()) {
            return;
        }

        const dto = JSON.stringify({
            xtotp: formValues.xtotp1
        });

        console.log(dto);

        await axios
            .put(
                process.env.REACT_APP_BASE_API_URL + "/Personas/FinalizarConfiguracionAutenticacion2FA",
                dto,
                configHttp
            )
            .then((response) => {
                MostrarToast("Transferencias seguras activadas correctamente");
                history.push("/admin/profile");
            })
            .catch((err) => {
                if (err.request.status === 401) {
                    RedirigirALogin();
                } else {
                    setUnexpectedErrorMessage();
                }
            });
    }

    const onEnviarCodigoVerificacion = async (e) => {
        e.preventDefault();
        if (!validateForm()) {
            return;
        }

        const dto = JSON.stringify({
            xtotp: formValues.xtotp,
            xtotpType: totptype,
        });

        console.log(dto);

        await axios
            .put(
                process.env.REACT_APP_BASE_API_URL + "/Personas/IniciarConfiguracionAutenticacion2FA",
                dto,
                configHttp
            )
            .then((response) => {
                MostrarPaso3(response.data.qrConfigurarGoogle2FA, response.data.secretConfigurarGoogle2FA);
            })
            .catch((err) => {
                if (err.request.status === 401) {
                    RedirigirALogin();
                } else {
                    setUnexpectedErrorMessage();
                }
            });
    }
    function MostrarPaso3(qrconfiguracion, secretConfig) {
        setqrConfiguracion(qrconfiguracion);
        setSecretConfiguracion(secretConfig);
        setDescripcionPaso(descripcionPaso3);
        setTituloPaso(tituloPaso3);
        setimagenDerecha(illustrationPaso3);

        setMostrarPaso1(false);
        setMostrarPaso2(false);
        setMostrarPaso3(true);
        setTimeout(() => onOpenPopupQRConfiguracion(), 1000);

    }

    const onSolicitarCodigoVerificacion = async (e) => {
        e.preventDefault();
        setMostrarPaso1(false);
        await axios
            .get(process.env.REACT_APP_BASE_API_URL + "/Personas/EnviarCodigo2FA", configHttp)
            .then((response) => {
                setTotptype(response.data.tipoCodigo2FA);
                MostrarPaso2();
                return;
            })
            .catch((err) => {
                if (err.request.status === 401) {
                    RedirigirALogin();

                    return;
                } else {
                    setUnexpectedErrorMessage();
                }
            });
    }

    function RedirigirALogin() {
        MostrarToast("Porfa inicia sesión o regístrate para poder continuar");
        history.push("/auth/sign-in");
    }

    return (
        <DefaultAuth illustrationBackground={imagenDerecha} image={imagenDerecha}>
            <Flex
                maxW={{ base: "100%", md: "max-content" }}
                w="100%"
                mx={{ base: "auto", lg: "0px" }}
                me="auto"
                h="100%"
                alignItems="start"
                justifyContent="center"
                mb={{ base: "30px", md: "60px" }}
                px={{ base: "25px", md: "0px" }}
                mt={{ base: "15px", md: "5vh" }}
                flexDirection="column"
            >
                <Box me="auto">
                    <Heading color={textColor} fontSize="36px" mb="10px">
                        Activar transferencias seguras
                    </Heading>
                    <Text
                        w={{ base: "100%", md: "45%" }}
                        mb="12px"
                        mt="24px"
                        color={textColorSecondary}
                        fontWeight="400"
                        fontSize="md"
                    >
                        De este modo cada vez que vayas a hacer una inversión nos enviarás un código autogenerado en tu teléfono móvil que solo conocerás tu y con el cual podremos confirmar que deseas realizar la transacción.
                        Es muy sencillo y son solo 3 pasos.
                    </Text>
                    <OrderedList
                        color={textColorSecondary}
                        ml="25px"
                        mt="5px">
                        <ListItem>Iniciamos solicitando un código de verificación</ListItem>
                        <ListItem>Generamos un QR para facilitarte la configuración Gooleg Authenticator</ListItem>
                        <ListItem>Nos envías un código de verificación</ListItem>
                    </OrderedList>

                    <Flex direction='column'>
                        <Text
                            color={textColor}
                            fontSize={{
                                base: "xl",
                                md: "lg",
                                lg: "lg",
                                xl: "lg",
                                "2xl": "md",
                                "3xl": "lg",
                            }}
                            mb='5px'
                            mt='15px'
                            fontWeight='bold'
                            me='14px'>
                            {tituloPaso}
                        </Text>
                        <Text
                            color='secondaryGray.600'
                            fontSize={{
                                base: "sm",
                            }}
                            w={{ base: "100%", md: "45%" }}
                            fontWeight='400'
                            me='14px'>
                            {descripcionPaso}
                        </Text>
                    </Flex>
                    <Flex>
                        <Flex
                            zIndex="2"
                            direction="column"
                            w={{ base: "100%", md: "420px" }}
                            maxW="100%"
                            background="transparent"
                            borderRadius="15px"
                            mx={{ base: "auto", lg: "unset" }}
                            me="auto"
                            mt="15px"
                            mb={{ base: "20px", md: "auto" }}
                            align={"center"}>
                            <Flex display={mostrarPaso1 ? "flex" : "none"}>
                                <Button
                                    fontSize="sm"
                                    variant="brand"
                                    fontWeight="500"
                                    onClick={onSolicitarCodigoVerificacion}
                                >
                                    Solicitar código verficación
                                </Button>
                            </Flex>
                            <Flex display={mostrarPaso2 ? "flex" : "none"}
                                flexDirection="column">
                                <Flex
                                    direction="column"
                                    w={{ base: "100%", md: "420px" }}
                                    maxW="100%"
                                >
                                    {Object.values(formErrors).map((error) => {
                                        return (
                                            <Alert
                                                key={"_alert_" + error}
                                                status="error"
                                                variant="left-accent"
                                            >
                                                <AlertIcon></AlertIcon>
                                                <AlertDescription>{error}</AlertDescription>
                                            </Alert>
                                        );
                                    })}
                                </Flex>
                                <FormControl>
                                    <FormLabel
                                        display="flex"
                                        ms="4px"
                                        fontSize="sm"
                                        fontWeight="500"
                                        color={textColor}
                                        mb="8px"
                                        mt="20px"
                                    >
                                        Código Verificación <Text color={brandStars}>*</Text>
                                    </FormLabel>
                                    <Input
                                        isRequired={true}
                                        variant="auth"
                                        fontSize="sm"
                                        ms={{ base: "0px", md: "0px" }}
                                        type="text"
                                        mb="24px"
                                        fontWeight="500"
                                        size="lg"
                                        name="xtotp"
                                        value={formValues.xtotp}
                                        onChange={handleChange}
                                    />
                                    <Button
                                        fontSize="sm"
                                        variant="brand"
                                        fontWeight="500"
                                        onClick={onEnviarCodigoVerificacion}
                                    >
                                        Enviar
                                    </Button>
                                </FormControl>
                            </Flex>
                            <Flex display={mostrarPaso3 ? "flex" : "none"}>
                                <FormControl>
                                    <FormLabel
                                        display="flex"
                                        ms="4px"
                                        fontSize="sm"
                                        fontWeight="500"
                                        color={textColor}
                                        mb="8px"
                                        mt="20px"
                                    >
                                        Código Google Authenticator<Text color={brandStars}>*</Text>

                                    </FormLabel>
                                    <Input
                                        isRequired={true}
                                        variant="auth"
                                        fontSize="sm"
                                        ms={{ base: "0px", md: "0px" }}
                                        type="text"
                                        mb="24px"
                                        fontWeight="500"
                                        size="lg"
                                        name="xtotp1"
                                        value={formValues.xtotp1}
                                        onChange={handleChange}
                                    />
                                    <Button
                                        fontSize="x-small"
                                        fontWeight="5"
                                        onClick={onOpenPopupQRConfiguracion}
                                    >
                                        Abri QR configuración Google Authenticator
                                    </Button>
                                    <Button
                                        fontSize="sm"
                                        variant="brand"
                                        fontWeight="500"
                                        onClick={onFinalizarVerificacion}
                                    >
                                        Finalizar
                                    </Button>
                                </FormControl>
                            </Flex>
                        </Flex>
                        <Flex>

                        </Flex>
                    </Flex>

                </Box>
            </Flex>
            <Modal isOpen={isOpenPopupQRConfiguracion} onOpen={onOpenPopupQRConfiguracion} onClose={onClosePopupQRConfiguracion} size="4xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Configura tu Google Authenticator</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <SimpleGrid columns={2} spacing={10} ml="50px">
                            <Box >
                                <Flex direction='column'>
                                    <Text
                                        color={textColor}
                                        fontSize={{
                                            base: "xl",
                                            xs: "sm",
                                            md: "lg",
                                            lg: "lg",
                                            xl: "lg",
                                            "2xl": "md",
                                            "3xl": "lg",
                                        }}
                                        mb='5px'
                                        mt='15px'
                                        fontWeight='bold'
                                        me='14px'>
                                        Añade la configuración a tu Google Athenticator
                                    </Text>
                                    <Text
                                        color='secondaryGray.600'
                                        fontSize={{
                                            base: "sm",

                                        }}
                                        fontWeight='400'
                                        me='14px'>
                                        Es muy fácil, lo único que tienes que hacer el leer el siguiente código QR
                                        con tu teléfono móvil o abrir la aplicación Google Authenticator y añadir
                                        una cuenta introduciendo la siguiente clave:
                                    </Text>
                                    <Text
                                        color={textColor}
                                        fontSize={{
                                            base: "xl",
                                            md: "lg",
                                            lg: "lg",
                                            xl: "lg",
                                            "2xl": "md",
                                            "3xl": "lg",
                                        }}
                                        mb='5px'
                                        mt='30px'
                                        ml='30px'
                                        fontWeight='bold'
                                        me='14px'>
                                        {secretConfiguracion}
                                    </Text>
                                </Flex>
                            </Box>
                            <Box  >
                                <Flex ml="15px" >
                                    <Image src={qrConfiguracion} alt='Dan Abramov' />
                                </Flex>
                            </Box>
                            <Box bg="tomato" >
                                <Flex >

                                </Flex>
                            </Box>
                            <Box bg="tomato" >
                                <Flex >

                                </Flex>
                            </Box>

                        </SimpleGrid>

                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="brand" mr={3} onClick={onClosePopupQRConfiguracion}>
                            Hecho!
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </DefaultAuth>
    );
}
export default TwoFAEnablement;