// Chakra imports
import {
  Box,
  Flex,
  Icon,
  Progress,
  Text,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  ModalCloseButton,
  useDisclosure,
  FormControl,
  FormLabel,
  Select,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
  Alert,
  AlertIcon,
  AlertDescription,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import IconBox from "components/icons/IconBox";
import Menu from "components/menu/MainMenu";
import InformacionWallet from "views/admin/profile/components/InformacionWallet";
import IrAVerificar from "views/admin/profile/components/IrAVerificar";

import React, { useState } from "react";
// Assets
import { MdAttachMoney } from "react-icons/md";
import { configHeader } from "httpConfig";
import axios from "axios";
import toast from 'react-simple-toasts';
import { Mixpanel } from 'mixpanel';

export default function Banner(props) {
  const { used, total, importeRecuperado, interesesGanados, saldoDisponible, tieneWallet, abrirAnyadirTarjeta } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const brandColor = useColorModeValue("brand.500", "white");
  const textColorSecondary = "gray.400";
  const box = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const textColor = useColorModeValue("navy.700", "white");
  const [sliderValue, setSliderValue] = useState(50);
  const [tarjetasCredito, setTarjetasCredito] = useState([]);
  const [tarjetaCredito, setTarjetaCredito] = useState();
  const [mostrarError, setMostrarError] = useState(false);
  const [mensajeError, setMensajeError] = useState();

  function AbrirAnyadirSaldo() {
    setMostrarError(false);
    onOpen();
    const header = configHeader();
    axios
      .get(process.env.REACT_APP_BASE_API_URL + "/Wallet", header)
      .then((response) => {
        setTarjetasCredito(response.data.tarjetasCredito);
      })
      .catch((err) => {
        MostrarToast("Error al obtener las tarjetas del usuario");
        console.log(err);
      });

    Mixpanel.track('Abrir_AnyadirSaldo');
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    setMostrarError(false);

    if (tarjetaCredito === "" || tarjetaCredito === undefined || tarjetaCredito === null) {

      setMensajeError("Debes seleccionar una tarjeta para realizar el deposito");
      setMostrarError(true);

      return;
    }

    const anyadirSaldoDto = JSON.stringify({ tarjetaId: tarjetaCredito, importe: sliderValue, tipo: 0 });

    const header = configHeader();

    await axios
      .post(
        process.env.REACT_APP_BASE_API_URL + "/AnyadirSaldo",
        anyadirSaldoDto,
        header
      )
      .then((response) => {
        MostrarToast("Solicitud de añadir saldo creada correctamente, en unos minutos confirmamos que puede usar el saldo en su wallet")
        onClose();
        window.location.reload(false);
      })
      .catch((err) => {
        if (err.response.data.Message === "errors.cowallet.wallet.noPuedeCrearOrdenDepositoBonoBienvenidaSuperior100Eur") {
          setMensajeError("El importe máximo que puedes añadir con el bono de bienvenida son 100€ ");
        }
        else {
          setMensajeError("Error al crear la solicitud de añadir saldo " + err);
        }
        setMostrarError(true);
        console.log(err.response.data.Message);
        console.log(err);
      });

    Mixpanel.track('Enviar_AnyadirSaldo');
  }
  function MostrarToast(mensaje) {

    toast(mensaje, { position: 'bottom-center', duration: 5000 });

  }

  return (
    <Card mb={{ base: "0px", lg: "20px" }} align='center'>

      <Flex w='100%'>
        <Menu
          abrirAnyadirTarjeta={props.abrirAnyadirTarjeta}
          abrirAnyadirSaldo={AbrirAnyadirSaldo} ms='auto' />
      </Flex>
      <IconBox
        mx='auto'
        icon={
          <Icon as={MdAttachMoney} color={brandColor} h='46px' w='46px' />
        }
        bg={box}
      />
      <Text color={textColorPrimary} fontWeight='bold' fontSize='2xl' mt='10px'>
        Tu Wallet
      </Text>
      <Text
        color={textColorSecondary}
        fontSize='md'
        maxW={{ base: "100%", xl: "80%", "3xl": "60%" }}
        mx='auto'>
        Supervisa el estado de tus inversiones de una forma fácil
      </Text>
      {
        tieneWallet === true ?

          used === "0,00 €" ?
            <Flex mx='auto' mt='15px' >
              <Flex align='center' direction='column'>
                <Text color={textColorSecondary} maxW={{ base: "100%", xl: "80%", "3xl": "60%" }}
                  mx='auto'>
                  Añade saldo para comenzar a invertir
                </Text>
                <Button
                  variant="brand"
                  size="md"
                  mt='15px'
                  onClick={(event) => {
                    event.preventDefault();
                    AbrirAnyadirSaldo();
                  }}
                >
                  Añadir Saldo
                </Button>
              </Flex>
            </Flex>
            :

            <InformacionWallet
              used={used}
              total={total}
              importeRecuperado={importeRecuperado}
              interesesGanados={interesesGanados}
              saldoDisponible={saldoDisponible} />

          :
          <IrAVerificar />
      }

      <Modal isOpen={isOpen} onClose={onClose} size="4xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Añadir saldo</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex
              display={
                mostrarError ? "flex" : "none"
              }
              direction="column"

              mb='10px'
            >
              <Alert
                key={"_alert_" + mensajeError}
                status="error"
                variant="left-accent"
              >
                <AlertIcon></AlertIcon>
                <AlertDescription>{mensajeError}</AlertDescription>
              </Alert>
            </Flex>
            <FormControl>
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                Tarjeta <Text color={brandStars}>*</Text>
              </FormLabel>
              <Select placeholder='Selecciona una tarjeta ' onChange={(e) => setTarjetaCredito(e.target.value)} >
                {
                  tarjetasCredito.map((tarjeta, index) => (
                    <option value={tarjeta.id}>{tarjeta.alias} ({tarjeta.brand}) **********</option>

                  ))
                }
              </Select>
              <FormLabel
                mt='2%'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                Importe en Euros <Text color={brandStars}>*</Text>
              </FormLabel>
              <Slider
                mt='2%'
                aria-label="slider-ex-1"
                min={5}
                max={1000}
                defaultValue={50}
                onChange={(val) => setSliderValue(val)}
              >
                <SliderMark
                  value={sliderValue}
                  textAlign="center"
                  bg="blue.500"
                  color="white"
                  mt="-10"
                  ml="-5"
                  w="12"
                >
                  {sliderValue}€
                </SliderMark>
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
              </Slider>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="brand" mr={3} onClick={onSubmit} >
              Añadir
            </Button>
            <Button variant="ghost" onClick={onClose}>Cerrar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Card>
  );
}
