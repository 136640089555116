/* eslint-disable */
/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useState, useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  Link,
  Alert,
  AlertIcon,
  AlertDescription,
} from "@chakra-ui/react";
// Custom components
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.png";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import {  createUserWithEmailAndPassword  } from 'firebase/auth';
import { auth } from 'firebaseConfig';
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

import {guardarTokenEnLocalStorage} from "views/auth/authenticationUtils";
import { Mixpanel } from 'mixpanel';

function Registration() {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
  
  const provider = new GoogleAuthProvider();
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  const history = useHistory();

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('');
  const [showError, setshowError] = useState('hidden');
  const [errorDescription, setErrorDescription] = useState('');
  
  useEffect(() => {

    Mixpanel.track('Acceso_Registro');

  }, []);

  function mostraryEstablecerDescripcionDelError(descripcion)
  {
    setshowError("visible");
    setErrorDescription(descripcion);
  }

  function gestionarErrorAutenticacion(errorCode,errorMessage)
  {
    console.log(errorCode, errorMessage);
    setshowError("visible");
    switch(errorCode) {
      case 'auth/invalid-email':
        mostraryEstablecerDescripcionDelError("Por favor introduzca un correo electronico válido");
        return;
      case 'auth/email-already-in-use':
        mostraryEstablecerDescripcionDelError("Ya existe un usuario con ese email. Por favor vaya a iniciar sesión");
        return;
      default:
        mostraryEstablecerDescripcionDelError(errorCode);
        return;
    }
  }


  function navegarAHome()
  {
    history.push("/");
  }

  const onSignInWithPopup = async (e) =>{
    e.preventDefault();
    
    Mixpanel.track('Abrir_RegistroConGoogle');

    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;

        // The signed-in user info.
        const user = result.user;
        guardarTokenEnLocalStorage(user);
        navegarAHome();
        return;
      }).catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        console.log(errorCode);
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);

        gestionarErrorAutenticacion(errorCode,errorMessage);
      });    
  }

  const onSubmit = async (e) => {
    e.preventDefault()

    Mixpanel.track('Clic_RegistroConFormulario');

    setshowError("hidden");
    await createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
          // Signed in
          const user = userCredential.user;
          console.log(user);
          guardarTokenEnLocalStorage(user);
          navegarAHome()
      })
      .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          gestionarErrorAutenticacion(errorCode,errorMessage);
      });

 
  }
  return (
    
    <DefaultAuth illustrationBackground={illustration} image={illustration}>  
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w='100%'
        mx={{ base: "auto", lg: "0px" }}
        me='auto'
        h='100%'
        alignItems='start'
        justifyContent='center'
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection='column'>
        <Box me='auto'>
          <Heading color={textColor} fontSize='36px' mb='10px'>
            Crear una cuenta
          </Heading>
          <Text
            mb='36px'
            ms='4px'
            color={textColorSecondary}
            fontWeight='400'
            fontSize='md'> 
            Comienza a invertir en préstamos rentables sin fronteras
          </Text>
        </Box>
        <Flex
          zIndex='2'
          direction='column'
          w={{ base: "100%", md: "420px" }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: "auto", lg: "unset" }}
          me='auto'
          mb={{ base: "20px", md: "auto" }}>
          <Flex>
            <Alert visibility={showError}  status="error" variant="left-accent">
              <AlertIcon></AlertIcon>
              <AlertDescription>{errorDescription}</AlertDescription>
            </Alert>
          </Flex>  
          <Button
            fontSize='sm'
            me='0px'
            mb='26px'
            py='15px'
            h='50px'
            borderRadius='16px'
            bg={googleBg}
            color={googleText}
            fontWeight='500'
            _hover={googleHover}
            _active={googleActive}
            _focus={googleActive}
            onClick={onSignInWithPopup}>
            <Icon as={FcGoogle} w='20px' h='20px' me='10px'/>
            Sign in with Google
          </Button>
          <Flex align='center' mb='25px'>
            <HSeparator />
            <Text color='gray.400' mx='14px'>
              o
            </Text>
            <HSeparator />
          </Flex>
          <FormControl>
            <FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='8px'>
              Email<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              type='email'
              placeholder='mail@simmmple.com'
              mb='24px'
              fontWeight='500'
              size='lg'
              onChange={(e) => setEmail(e.target.value)} 
            />
            <FormLabel
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              display='flex'>
              Contraseña <Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size='md'>
              <Input
                isRequired={true}
                fontSize='sm'
                placeholder='Min. 8 characters'
                mb='24px'
                size='lg'
                type={show ? "text" : "password"}
                variant='auth'
                onChange={(e) => setPassword(e.target.value)}
              />
              <InputRightElement display='flex' alignItems='center' mt='4px'>
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: "pointer" }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>            
            <Flex justifyContent='space-between' align='center' mb='24px'>
            <Text
                color={textColorSecondary}
                  fontSize='sm'>
                  Al crear la cuanta de Cowallet aceptas  &nbsp;
                  <Link color="brand.500" href="https://www.cowallet.app/use-terms" isExternal="true">
                     nuestros terminos de servicio y politica de privacidad
                  </Link>   
            </Text>
            </Flex>
            <Button
              fontSize='sm'
              variant='brand'
              fontWeight='500'
              w='100%'
              h='50'
              mb='24px'
              onClick={onSubmit}  >
              Crear Cuenta
            </Button>
          </FormControl>
          <Flex
            flexDirection='column'
            justifyContent='center'
            alignItems='start'
            maxW='100%'
            mt='0px'>
            <Text color={textColorDetails} fontWeight='400' fontSize='14px'>
              Ya tienes cuenta?
              <NavLink to='/auth/sign-up'>
                <Text
                  color={textColorBrand}
                  as='span'
                  ms='5px'
                  fontWeight='500'>
                  Inicia sesión aquí
                </Text>
              </NavLink>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default Registration;
